import React from 'react';
import NavBar from './home/NavBar';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Alerts from '../Alerts';
import AppRoutes from './AppRoutes';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '2em',
    marginBottom: '4em'
  }
}));
// class Main extends Component {
const Main = props => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <NavBar {...props} />
      <div className="col-sm-12" >
        <Alerts />
      </div>
      <Container maxWidth='lg' className={classes.root} id="loggedin-main-content" role="main">
        <AppRoutes />
      </Container>
    </React.Fragment>
  );
};

export default Main;
