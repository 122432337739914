import React from 'react'
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import HistoryIcon from '@material-ui/icons/History';
import { useTranslation } from "react-i18next";
import ACL from '../ACL';
import { Hidden } from '@material-ui/core';

const styles = {
    listItem: {
        justifyContent: "flex-end",
        padding: "0px"
    },
    listItemText: {
        // color: '#0077c8',
        flex: 'none',
        padding: '0px 10px',
        margin: '0px'
    }, 
    activeLink: {
        borderBottom: "4px solid #702f8a", 
        color:"#702f8a"
    },
    defaultLink: {
        borderBottom: "4px solid #ffffff", 
        borderRadius: "0px",
        textDecoration: "none"
    },
    noPadding: {
        padding: "0px"
    }
}

const TopNav = () => {
    // console.log("Data Stored and using in TopNav Compon:->> "+ aclData.acl['210']);
    const { t } = useTranslation();
    return (

        <List component="nav" className="row" style={styles.noPadding}>
            <ListItem component="div" style={styles.listItem}>
                <ACL functionId="2">
                    <ListItemText style={styles.listItemText}>
                        <Button color="inherit" disableRipple  component={NavLink} style={styles.defaultLink} activeStyle={styles.activeLink} to="/home/index">
                            <HomeIcon /><Hidden xsDown>&nbsp;{t('appLabels.homeLabel')}</Hidden>
                        </Button>
                    </ListItemText>
                </ACL>
                <ACL functionId="3">
                    <ListItemText style={styles.listItemText}>
                        <Button color="inherit" disableRipple  component={NavLink} style={styles.defaultLink} activeStyle={styles.activeLink} to="/home/users">
                            <PeopleIcon/><Hidden xsDown>&nbsp;{t('appLabels.userLabel')}</Hidden>
                        </Button>
                    </ListItemText>
                </ACL>
                <ACL functionId="4">
                <ListItemText style={styles.listItemText}>
                    <Button color="inherit" disableRipple  component={NavLink} style={styles.defaultLink} activeStyle={styles.activeLink} to="/home/imports">
                        <CloudUploadIcon /><Hidden xsDown>&nbsp;{t('appLabels.importsLabel')}</Hidden>
                    </Button>
                </ListItemText>
                </ACL>
                <ACL functionId="5">
                    <ListItemText style={styles.listItemText}>
                        <Button color="inherit" disableRipple  component={NavLink} style={styles.defaultLink} activeStyle={styles.activeLink} to="/home/testhistory">
                            <HistoryIcon /><Hidden xsDown>&nbsp;{t('appLabels.historyLabel')}</Hidden>
                        </Button>
                    </ListItemText>
                </ACL>
            </ListItem >
        </List>
    )
}




export default TopNav;