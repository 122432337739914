import React,{Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from "react-redux";
import { compose } from "redux";

class Progress extends Component {
  
	constructor(props) {
	    super(props);
	    this.state = {
		    progress: {
		      showProgress: false
			}
	    };
	  }
	componentDidMount() {
		//   console.log(this.props)
	  }
	componentDidUpdate(){
		// console.log(this.props)
	}
	render() {
  return (
    <div>
    {this.props.progress.showProgress &&
    <LinearProgress className='cb-higher-education-color' /> 
    }
    </div>
  );
}
}
const mapStateToProps = state => ({
	  ...state
	});
export default compose(
  connect(
    mapStateToProps
  )
)(Progress);
