import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Main from "./components/Main";
import NotFound from "./components/NotFound";
import ForgotPassword from "./components/login/ForgotPassword";
import SignIn from "./components/login/SignIn";
import SignUp from "./components/login/SignUp";
import ResetPassword from "./components/login/ResetPassword";
import AboutThisApp from "./components/AboutThisApp";


export default (props) => (
  <Switch>
    <PrivateRoute {...props} path="/home" component={Main} />
    <Route path="/login" exact component={SignIn} />
    <Route path="/forgotpassword" component={ForgotPassword} />
    <Route path="/aboutThisApp" component={AboutThisApp} />
    <Route path="/register" component={SignUp} />
    <Route path="/resetpassword" component={ResetPassword}/>
    <Route exact path="/" render={() => (
      props.state.login && props.state.login.isloggedIn ? (
        <Redirect to="/home/index" />
      ) : (
          <Redirect to="/login" />
        )
    )} />
    <Route component={NotFound} />
  </Switch>
);
function PrivateRoute({ component: Component, ...rest }) {
 return (
    <Route
      {...rest}
      render={props =>
        rest.state && rest.state.login && rest.state.login.isloggedIn ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
      }
    />
  );
}