import React, { Component } from 'react'

// import TitleBar from './TitleBar'
import ErrorPage from './ErrorPage'

class NotFound extends Component {
	render() {
		return (
			<div className="App">
				<div >
			{/* <TitleBar /> */}
					<ErrorPage />
				</div>

			</div>

		)
	}
}

export default NotFound