import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Paper from '@material-ui/core/Paper'
import { submitLogin } from "../../redux/auth/action";
import { withTranslation } from "react-i18next";
import { aclAction } from "../../redux/auth/action";
import { showAlert, hideAlert } from '../../redux/alertActions';
import { PasswordValidationRegex } from '../../shared/Validation';
import { showProgress, hideProgress } from "../../redux/progressActions";
import Progress from "../../Progress"
import ReactGA from 'react-ga';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    },
  },
  paper: {
     padding: theme.spacing(1),
     color: theme.palette.text.secondary,
  },
  avatar: {

    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fedb00',
    color: '#1e1e1e'
  },
  container: {
    marginTop: "1em",
    marginBottom: '1em',
  },

  card: {
    maxWidth: 345,
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
})

class SignIn extends Component {
  constructor(props) {
    super(props);
    // reset login status
    this.form = React.createRef();
    this.state = {
      email: "",
      password: "",
      submitted: false,
      loginStatus: "",     
      showPassword: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.loginStatus = "";
    // this.props.hideAlert();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleMouseDownPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.hideAlert()
    this.props.showProgress();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.submitLogin(this.state).then(response => {
        console.log("Response from Login: ", response);

        if (response && response.result && response.result === "SUCCESS") {
          this.loginStatus = "SUCCESS";
          ReactGA.pageview("/login/success");
          ReactGA.event({
			  category: "Login",
			  action: "Login Success",
			});
			window.dataLayer.push({'event': "custom_event",
                             'eventAction': "Login Success",
                             'eventCategory': "Login",
                             'eventLabel': "Login"});
          this.props.aclAction(response).then(() => {
        	  this.props.hideProgress();
            this.props.history.push("/home/index");
          })
        };
        if (response && response.result && response.result === "FAILED") {
          this.loginStatus = "FAILED";
          ReactGA.pageview("/login/failed");
          ReactGA.event({
			  category: "Login",
			  action: "Login Failed",
			});
			    window.dataLayer.push({'event': "custom_event",
                                 'eventAction': "Login Failed",
                                 'eventCategory': "Login",
                                 'eventLabel': "Login"});
          this.props.showAlert(response.username, "error")
          this.form.current.childs[0].validate();
          this.form.current.childs[1].validate();
          this.setState({ submitted: false });
        }
        this.props.hideProgress();
      });
    }
  }
  componentDidMount() {
    if (this.props.login.isloggedIn) {
      this.props.history.push("/");
      return;
    }
    this.props.hideAlert()
    ValidatorForm.addValidationRule('invalidUser', () => {
      if (this.loginStatus === "FAILED") {
        return false;
      }
      return true;
    });
    // custom rule for password validation
    ValidatorForm.addValidationRule('matchPasswordCriteria', PasswordValidationRegex);
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule('invalidUser');
  }
  render() {
    const { classes } = this.props;
    const divStyle = {
      marginTop: "2em",
      marginBottom: "4em"
    };

    const { t } = this.props;
    return (
        <Container maxWidth="md" style={divStyle}>
            <div className="row">
              <Paper className={`paper-box-container`} >
              <div className="row col-xs-12 col-sm-12" style={{height:'10px'}}>
              <Progress />
              </div>
              <div className=" hDivider" ></div>
                <Container component="main" maxWidth="lg">
                  <div className={classes.paper}>

                    <Container maxWidth="xs">
                        <Avatar className={`${classes.avatar} margin-center`}>
                          <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h2" variant="h5" style={{textAlign: 'center',marginTop:10}}>
                          Sign in
                        </Typography>
                        <ValidatorForm
                          className={classes.form}
                          ref={this.form}
                          onSubmit={this.handleSubmit}
                          onError={errors => console.log(errors)}
                          noValidate
                        >
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t('signinTitle.email')}
                            name="email"
                            autoComplete="email"
                            onChange={this.handleChange}
                            autoFocus
                            validators={['required', 'isEmail']}
                            errorMessages={['Email Address is required.', 'Invalid Email Address.', '']}
                            value={this.state.email}
                          />
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            onChange={this.handleChange}
                            name="password"
                            label="Password"
                            type={this.state.showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment : (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      onMouseDown={this.handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                            }}
                            id="password"
                            autoComplete="current-password"
                            validators={['required']}
                            errorMessages={['Password is required.']}
                            value={this.state.password}
                          />
                          <button type="submit" disabled={this.state.submitted} className="btn  btn-sm btn-primary full-width"> Sign In</button>
                          <Grid container className={classes.container}>
                            <Grid item className="col-xs-12 col-sm-5 no-padding">
                              <Link to="/forgotpassword" variant="body2">
                                {t('forgotPassword.forgotpassword')}
                              </Link>
                            </Grid>
                            <Grid item className="col-xs-12 col-sm-7 no-padding">
                              <Link to="/register" variant="body2" style={{whiteSpace:'nowrap'}}>
                                {t('signinTitle.donthaveanaccount')}
                              </Link>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                    </Container>

                  </div>
                </Container>

              </Paper>
            </div>
        </Container>
    );
  }
}
SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapDispatchToProps = dispatch => ({
  submitLogin: user => dispatch(submitLogin(user)),
  aclAction: acl => dispatch(aclAction(acl)),
  showAlert: (msg, style, heading) => dispatch(showAlert(msg, style, heading)),
  hideAlert: () => dispatch(hideAlert()),
  showProgress: () => dispatch(showProgress()),
  hideProgress: () => dispatch(hideProgress())
});
const mapStateToProps = state => ({
  ...state
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SignIn);
// export default withStyles(styles)(SignIn)
