import ActionTypes from "../constants/ActionTypes";

const initialState = {
  windowObj: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.STORE_WINDOW:
      return {
        type: action.type,
        windowObj: action.windowObj
      };
    default:
      return state;
  }
};
