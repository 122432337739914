import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { hideAlert } from './redux/alertActions'
import Grow from '@material-ui/core/Grow';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  
})

class Alerts extends Component {

  constructor(props) {
    super(props);
    this.state = {
		alert: {
      showAlert: false,
	  style: "", 
	  heading: "",
	  msg: ""
	}
    };
	  this.handleClose = this.handleClose.bind(this)
  }
	
	handleClose(event) {
		this.props.hideAlert(this.props.alert.id);
	}
	componentDidUpdate(){
		if(this.props.alert.showAlert){
			window.scroll(0,0);
		}
	}
	  render() {
		  const { classes } = this.props
    return (
		<Grow in={this.props.alert.showAlert} timeout={{ 'enter': 500, 'exit': 500 }}>
			<Container className={classes.container}>
			{this.props.alert.style === "success" &&
				<div className={` alertWidth alert alert-success alert-dismissible cb-alert-heading`} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}></button>
					<h4 className={`alertTitle`}>{this.props.alert.header ? this.props.alert.header : "Success"}</h4>
					<p>{this.props.alert.msg ? this.props.alert.msg : "Success message"}</p>
				</div>
			}
			{this.props.alert.style === "warning" &&
				<div className={` alertWidth alert alert-warning alert-dismissible cb-alert-heading`} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}></button>
					<h4 className={`alertTitle`}>{this.props.alert.header ? this.props.alert.header : "Warning"}</h4>
					<p>{this.props.alert.msg ? this.props.alert.msg : "Warning message"}</p>
				</div>
			}
			{this.props.alert.style === "error" &&
				<div className={` alertWidth alert alert-danger alert-dismissible cb-alert-heading`} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}></button>
					<h4 className={`alertTitle`}>{this.props.alert.header ? this.props.alert.header : "Error"}</h4>
					<p>{this.props.alert.msg ? this.props.alert.msg : "Error message"}</p>
				</div>
			}
				{this.props.alert.style === "info" &&
				<div className={` alertWidth alert alert-info alert-dismissible cb-alert-heading`} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}></button>
					<h4 className={`alertTitle`}>{this.props.alert.header ? this.props.alert.header : "Info"}</h4>
					<p>{this.props.alert.msg ? this.props.alert.msg : "Info message"}</p>
				</div>
			}
			</Container>
		</Grow>
    )
  }
}
Alerts.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapDispatchToProps = dispatch => ({
	hideAlert: id => dispatch(hideAlert(id))
});
const mapStateToProps = state => ({
  ...state
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withTranslation()
)(Alerts);