import { combineReducers } from "redux";
import login from "./auth/reducer";
import alert from "./alertReducer";
import progress from "./progressReducer";
import modal from "./modalReducer";
import window from "./windowReducers";
import paa from "./preAssessmentReducer"

export default combineReducers({
  login,
  alert,
  progress,
  modal,
  window,
  paa
});
