
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from '@material-ui/icons/Error';
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Card, CardContent, CardHeader, CardActions, Button, Fab,
  FormControl, Tooltip, MenuItem, Grid, LinearProgress, withStyles
} from "@material-ui/core";
import { useDispatch } from 'react-redux'
import { fileUpload, getAllCp,exportCP} from '../../redux/importActions';
import Dropzone from "react-dropzone";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { CloudUpload, Refresh as RefreshIcon, FindInPage as PageView, History} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import ACL from '../ACL';

import { getTestVersionForImport } from "../../redux/auth/action";
import { showAlert, hideAlert } from '../../redux/alertActions'
import { showProgress, hideProgress } from "../../redux/progressActions";
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper'
import { showModal, hideModal } from "../../redux/modalAction";
import { approveOrRejectCp, deleteCP } from '../../redux/importActions';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {},
  tableWrapper: {
    overflowX: "auto"
  },
  card: {
    textAlign: "left",
    marginBottom: "20px"
  },
  cardHeader: {
    backgroundColor: "dodgerblue",
    color: "white"
  },
  cardAction: {
    float: "right"
  },
  importBtn: {
    backgroundColor: "#fedb00",
    color: "#1e1e1e"
  },
  viewBtn: {
    backgroundColor: "#ffffff",
    color: "#000000",
    marginRight: "4px"
  },
  secondaryButton: {
    backgroundColor: '#e0e0e0',
    color: '#1e1e1e'
  },
  textValidator: {
    marginRight: theme.spacing(2),
    width: "100%"
  },
  importHistoryBtn: {
    textAlign: "left",
    padding: "10px",
    color: "white",
    backgroundColor: "dodgerblue",
    display: "flex",
    alignItems: "center"
  },
  filteDeleteBtn: {
    paddingTop: "13px",
    fontSize: "18px",
    fontWeight: 600
  },
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }, error: {
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: "10px",
    color: "red"
  },
  progressBar: {
    marginTop: '10px',
    height: '15px'
  }
}));
const CustomLinearProgress = withStyles({
  barColorPrimary: {
    backgroundColor: '#702f8a',
    backgroundImage: 'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
    backgroundSize: '40px 40px'
  },
})(LinearProgress);
export default function Imports(props) { 
  const classes = useStyles();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(true);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const formRef = useRef();
  const [testVersion, setTestVersion] = React.useState([]);
  const [uploadObj, setUploadObj] = React.useState({
    fileDesc: "",
    cbVersion: "",
    testVersion: ""
  });
  const dispatch = useDispatch();
  const [progressStatus, setProgressStatus] = React.useState(0);
  const [isProgressBarVisible, setProgressBarVisible] = React.useState(false);
  const dataTableRef = useRef();
  function onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
    else {
      if (rejectedFiles.length > 0){
        setSelectedFile(null);
        if(rejectedFiles.length > 1)
          dispatch(showAlert("More than one content package file cannot be uploaded","error"));
        else
          dispatch(showAlert("Unsupported file format ","error"));
      }
        
    }
  }
 
  

  function handleChange(e) {
    const { name, value } = e.target;
    setUploadObj({ ...uploadObj, [name]: value });
  }

  function closeModel() {
	  dispatch(hideModal());
  }
  
  const performPublishCP = (...statusDescription) => {
    dispatch(hideAlert());
    if(statusDescription[2] === "Archived"){
      dispatch(showModal({
        title: "Publish Content Package",
            message: "Are you sure you want to re-publish the older version of the content package?",
            confirmAction: handlePublishCP,
            confirmActionParams: statusDescription,
            closeModal: closeModel,
            dialogAttr: {
              fullWidth:true,
              maxWidth: "sm"
            }
      },"confirm"));
    }else{
      dispatch(showModal({
        title: "Publish Content Package",
            message: "Are you sure you want to publish the Content Package?",
            confirmAction: handlePublishCP,
            confirmActionParams: statusDescription,
            closeModal: closeModel,
            dialogAttr: {
              fullWidth:true,
              maxWidth: "sm"
            }
      },"confirm"));
    }
  }
  
  const handlePublishCP = (cpId, cpStatusId,statusDescription) => {
	  let cpData = {
		  cpUploadedTestStatus: "8",
	      cpId: cpId
	    }
	  dispatch(approveOrRejectCp(cpData)).then(res => {
			  if(res === "Success") {
				  dispatch(showAlert("Content Package published successfully","success"))
				  refreshImportList();
			  } else {
				  dispatch(showAlert("Content Package publish failed","error")) 
			  }
			  dispatch(hideProgress())
		  });
	  closeModel();
  }
  
  function performDeleteCP(...cpId) {
	  dispatch(hideAlert());
	  dispatch(showModal({
		  title: "Delete Content Package",
          message: "Are you sure you want to delete the Content Package?",
          confirmAction: handleDeleteCP,
          confirmActionParams: cpId,
          closeModal: closeModel,
          dialogAttr: {
        	  fullWidth:true,
        	  maxWidth: "sm"
          }
	  },"confirm"));
  }
  function handleDeleteCP(cpId) {
	  dispatch(deleteCP({cpUploadId:cpId})).then(res => {
		  if(res === "SUCCESS") {
			  dispatch(showAlert("Content Package entry deleted successfully","success"))
			  refreshImportList();
		  } else {
			  dispatch(showAlert("Content Package entry deletion failed","error")) 
		  }
		  dispatch(hideProgress())
	  });
	  closeModel();
  	}
  
  function handleSubmit(e) {
    let fd = new FormData();
    dispatch(hideAlert());
    fd.append('File', selectedFile);
    fd.append('fileDesc', uploadObj.fileDesc);
    fd.append('cbVersion', uploadObj.cbVersion);
    fd.append('testVersion', uploadObj.testVersion);
    let onUploadProgress = function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setProgressStatus(percentCompleted)
    }
    setProgressBarVisible(true);
    dispatch(fileUpload(fd, onUploadProgress)).then((response) => {
      if (response && response.data && response.data.status === "SUCCESS") {
        reset();
        dispatch(showAlert("Content Package Imported Successfully and in process to create test. Refresh the history to check the status", "success"))
        if(dataTableRef.current) {
  		  dataTableRef.current.onQueryChange()
  	  	}
      }else if(response && response.data && response.data.status==="FAILED" && response.data.msg.FILE_TYPE){
          dispatch(showAlert("Content Package import failed, "+ response.data.msg.FILE_TYPE,"error"))
      }else if (response === undefined || response === null
        || response.data === null || response.data === undefined || (response && response.data && response.error !== null)) {
        dispatch(showAlert("Content Package import failed , please contact administrator", "error"))
      }
      setProgressBarVisible(false);
      setProgressStatus(0);
    })

  }


  useEffect(() => {
    // custom rule for user role validation
    ValidatorForm.addValidationRule('requiredTestVersion', value => {
      if (uploadObj && value === 0) {
        return false;
      }
      return true;
    });

    if (selectedFile === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  useEffect(() => {
    dispatch(hideAlert());
    getTestVersions();

    ValidatorForm.addValidationRule('fileDescriptionFieldLenght', value => {
      if (value.length > 100) {
        return false;
      }
      return true;
    });
  }, [])


  const getTestVersions = () => {
    dispatch(getTestVersionForImport()).then(res => {
      if (res && res.testVersion && res.testVersion.length > 0) {
        setTestVersion(res.testVersion)
      }
    });
  }
  function refreshImportList() {
	  if(dataTableRef.current) {
		  dataTableRef.current.onQueryChange()
	  }
	  dispatch(hideProgress());
  }
  const handleDownload = (cpId) => {
	  dispatch(showProgress());
    const payload = {
      cpId: cpId
    };
    
    dispatch(exportCP(payload)).then(res => {
    	if(res.status === "SUCCESS") {
    	      const link = document.createElement("a");
    	      link.download = res.FILE_NAME;
    	      link.href = res.FILE_URL;
    	      document.body.appendChild(link);
    	      link.click();
    	      document.body.removeChild(link);
    	      link.remove();
    	} else {
    		dispatch(showAlert("Content Package not found , please contact administrator", "error"))
    	}
    	dispatch(hideProgress());
    })

  }
  
  const handleView = (cpId) => {
    dispatch(hideAlert());
	  props.history.push('/home/imports/practiceTest/' + cpId);
  }
  
  const handleCancel = event => {
    setUploadObj({
      fileDesc: "",
      cbVersion: "",
      testVersion: ""
    });
    setSelectedFile(null);
    formRef.current.resetValidations();
    dispatch(hideAlert());
  }

  const reset = event => {
    handleCancel();
    setDisabled(true);
  };
  return (
    <React.Fragment>
      <Grid item xs={12} className='titleBar' align='center'>
        <Typography variant='h5' component='h2' color='primary'>
          {t('appLabels.importsLabel')}
        </Typography>
      </Grid>
      <Grid item xs={12} >
        <ACL functionId="11">
          <div className="col-sm-12">
            <ValidatorForm ref={formRef} onSubmit={handleSubmit} noValidate>
              <Card className={classes.card}>
                <CardHeader
                  avatar={<CloudUpload />}
                  title="File Import"
                  className="section-header"
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextValidator
                        className={classes.textValidator}
                        id="fileDesc"
                        name="fileDesc"
                        label="Import Description"
                        variant="outlined"
                        value={uploadObj.fileDesc}
                        validators={["required","fileDescriptionFieldLenght"]}
                        errorMessages={["Import Description is required","The Import Description cannot exceed 100 characters"]}
                        margin="normal"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <SelectValidator
                          variant="outlined"
                          value={uploadObj.testVersion}
                          onChange={handleChange}
                          name="testVersion"
                          label="Test Version"
                          className={classes.selectEmpty}
                          validators={["required"]}
                          errorMessages={["Test Version should be selected"]}
                        >                          {testVersion.map((item, index) =>
                          <MenuItem value={item.categoryId} key={index}>
                            {item.categoryName}
                          </MenuItem>
                        )}
                        </SelectValidator>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextValidator
                        className={classes.textValidator}
                        id="cbVersion"
                        label="CB Version"
                        name="cbVersion"
                        variant="outlined"
                        value={uploadObj.cbVersion}
                        onChange={handleChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Dropzone
                    onDrop={onDrop}
                    multiple={false}
                    accept="zip,application/zip,application/x-zip,application/x-zip-compressed"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps({ className: "dropzone" })} />
                        <CloudUpload fontSize="large" />
                        <p>
                          <b>Drop file here to upload</b>&nbsp;(Or Click)
                      </p>
                      </div>
                    )}
                  </Dropzone>
                  {selectedFile && (
                    <div className={classes.filteDeleteBtn}>
                      {selectedFile.name} &nbsp;&nbsp;
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setSelectedFile(null)}
                        className={classes.button}
                      >
                        Remove <DeleteIcon className={classes.rightIcon} />
                      </Button>
                    </div>
                  )}
                  {isProgressBarVisible && <CustomLinearProgress variant="determinate" className={classes.progressBar} value={progressStatus} />}
                </CardContent>
                <CardActions className={classes.cardAction}>
                  <button type="button" onClick={reset} className="btn btn-sm btn-secondary">Cancel</button>
                  <button type="submit" disabled={disabled} className="btn  btn-sm btn-primary full-width"> Import</button>

                </CardActions>
              </Card>
            </ValidatorForm>
          </div>
        </ACL>
      </Grid>
      <Grid item xs={12} >
        <div className="col-sm-12">
          <Card className={classes.card}>
            <CardHeader
              avatar={<History />}
              title="Import History"
              className="section-header"
              action={
                <Tooltip title="Refresh" aria-label="refresh">
                  <Fab
                    className={classes.viewBtn}
                    size="small"
                  >
                    <RefreshIcon onClick={() => {refreshImportList()}} />
                  </Fab>
                </Tooltip>
              }
            />
            <CardContent>
            
            <Paper className={classes.root}>
            <MaterialTable
            title=""
            	tableRef={dataTableRef}
            data={query => 
            new Promise((resolve, reject) => {
            	dispatch(getAllCp({query })).then(result => {
            		resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.totalCount,
                      })
            	})
          })
            
            }
            options={{
            	actionsColumnIndex:5,
            	headerStyle:{fontWeight: 'bold'
            	},
            	actionsCellStyle: {
            		width:250,
            		paddingLeft:65
            	},
            	searchFieldStyle: {searchPlaceholder:'Search by File Name'},
              search: true,
              sorting: true,
              grouping: false,
              draggable: false,
              pageSize: 10,
              pageSizeOptions:[10,25,50,100,250],
              emptyRowsWhenPaging:false
            }}
            localization={{
            	header: {
                    actions: 'Action'
                },
                toolbar: {
                	searchTooltip:'Search by File Name',
                	searchPlaceholder:'Search by File Name'
                },
                pagination : {
                  firstAriaLabel: 'First Page',
                  previousAriaLabel: 'Previous Page',
                  lastAriaLabel: 'Last Page',
                  nextAriaLabel: 'Next Page'
                }
            }}
            columns={[
            	
            	{ title: 'S. No', field: 'id',
            		render: rowData => rowData.id,
            		defaultSort:'desc',
            		},
                { title: 'Test Version', field: 'tstversion' ,
                		render: rowData => rowData.cpTestCategory.categoryName,
                		},
        		{ title: 'File Name', field: 'fileName' ,
            		render: rowData => rowData.fileName,
            		},
        		{ title: 'Import Date', field: 'date' ,
                render: rowData => new Date(rowData.createdOn).toLocaleString(),
              defaultSort:'desc',
            		},
        		{ title: 'Status', field: 'status' ,
            		render: rowData => rowData.cpStatusDetail.statusDescription,
            		}
              ]}
            components={{
                Action: props => (
                		<div>
                		<Tooltip title="Download" aria-label="Download">
                        <Fab
                          className={classes.viewBtn}
                          size="small"
                          onClick={e => handleDownload(props.data.id)}
                        >
                          <GetAppIcon />
                        </Fab>
                      </Tooltip>
                        {props.data.cpStatusDetail && (props.data.cpStatusDetail.statusId !== 1 && props.data.cpStatusDetail.statusId !== 2) ?
                          <Tooltip title="View" aria-label="view">
                            <Fab
                              className={classes.viewBtn}
                              size="small"
                              onClick={e => handleView(props.data.id)}
                            >
                              <PageView />
                            </Fab>
                          </Tooltip> : null}
                        {props.data.cpStatusDetail && props.data.cpStatusDetail.statusId === 2 && <Tooltip title="Error" aria-label="Error">
                          <Fab
                            className={classes.viewBtn}
                            size="small"
                            onClick={e => handleView(props.data.id)}
                          >
                            <ErrorIcon />
                          </Fab>
                        </Tooltip>}
                       
                        <ACL functionId="13">
                        {props.data.cpStatusDetail && props.data.cpStatusDetail.statusId
                          && (props.data.cpStatusDetail.statusId === 5 || props.data.cpStatusDetail.statusId === 7) ? (
                            < Tooltip title="Publish" aria-label="Publish">
                              <Fab
                                className={classes.viewBtn}
                                size="small"
                                onClick={() => performPublishCP(props.data.id, props.data.cpStatusDetail.statusId, props.data.cpStatusDetail.statusDescription)}
                              >
                                <PublishIcon />
                              </Fab>
                            </Tooltip>) : null
                        }
                      </ACL>
                      <ACL functionId="14">
                      { props.data.cpStatusDetail.statusId !== 8 && (
                    	  <Tooltip title="Delete" aria-label="Delete">
                      <Fab
                        className={classes.viewBtn}
                        size="small"
                        	onClick={() => performDeleteCP(props.data.id)}
                        	>
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>)
                      }
                      </ACL>
                        	
                        
                      </div>  
                ),
                
              }}
            actions={[
                {
                  icon: 'save',
                  tooltip: 'Preview',
                  onClick: (event, row) => {
                    // Do save operation
                	  handleDownload(row.id)
                  }
                }
                
                
              ]}
          />
          </Paper>
            

            </CardContent>
          </Card>
        </div>
      </Grid>
    </React.Fragment>
  );
}
