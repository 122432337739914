import React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { hideModal } from "./redux/modalAction";

import { default as modalTypes } from "./components/modals";

const MODAL_TYPES = {
  confirm: modalTypes.ConfirmModal,
  alert: modalTypes.AlertModal
};

const mapStateToProps = state => ({
  ...state.modal
});

const mapDispatchToProps = {
  hideModal
};

class ModalContainer extends React.Component {
  render() {
    if (!this.props.modalType) {
      return null;
    }
    if(!this.props.modalProps.closeModal){
      // modalProps.closeModal callback not found, so dispatching hideModal callback instead.
      this.props.modalProps.closeModal = this.props.hideModal;
    }
    const SpecifiedModal = MODAL_TYPES[this.props.modalType];
    return (
      <React.Fragment>
        <Dialog
          open={this.props.modalProps.open}
          onClose={this.props.modalProps.closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          {...this.props.modalProps.dialogAttr}
        >
          <SpecifiedModal {...this.props.modalProps}></SpecifiedModal>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
