import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import PrintIcon from "@material-ui/icons/Print";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CircularProgress from '@material-ui/core/CircularProgress';
import PreAssessmentStep1Content from "./PreAssessmentStep1Content";
import PreAssessmentStep2Content from "./PreAssessmentStep2Content";
import PreAssessmentStep3Content from "./PreAssessmentStep3Content";
import PreAssessmentStep4Content from "./PreAssessmentStep4Content";
import PreAssessmentStep5Content from "./PreAssessmentStep5Content";
import PreAssessmentCompletion from "./PreAssessmentCompletion";

import { useDispatch, connect } from "react-redux";
import { hideAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";

import {
  goToNextStep,
  goToPreviousStep,
  finishPAASession,
  getCurrentPAASession,
  printResources,
} from "../../redux/preAssessmentActions";

const getSteps = () => {
  return [
    {
      id: 1,
      title: "Select Institution",
      content: <PreAssessmentStep1Content />,
    },
    {
      id: 2,
      title: "Importance of the Assessment and Why it Matters",
      content: <PreAssessmentStep2Content />,
    },
    {
      id: 3,
      title: "Sample Tests",
      content: <PreAssessmentStep3Content />,
    },
    {
      id: 4,
      title: "Developmental Education Options (Course Options)",
      content: <PreAssessmentStep4Content />,
    },
    {
      id: 5,
      title: "Institutional and Community Resources and Tips for Success",
      content: <PreAssessmentStep5Content />,
      print: true,
    },
  ];
};

const WizardTitle = () => (
  <Grid item xs={12} className="titleBar" align="center">
    <Typography variant="h5" component="h1" color="primary">
      Texas Success Initiative Pre-Assessments 2.0
    </Typography>
  </Grid>
);
const WizardStepper = (props) => (
  <Grid item xs={12}>
    <Stepper activeStep={props.activeStep} alternativeLabel>
      {props.steps.map((step) => (
        <Step key={step.id}>
          <StepLabel>{step.title}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Grid>
);
const WizardContent = (props) => (
  <Grid item xs={12}>
    {props.activeStep === props.steps.length ? (
      <PreAssessmentCompletion />
    ) : (
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography
              variant="h3"
              className="cb-wizard-content-title"
              align="center"
            >
              Step {props.activeStep + 1} &mdash;{" "}
              {props.steps[props.activeStep].title}
            </Typography>
          }
        ></CardHeader>
        <CardContent>{props.steps[props.activeStep].content}</CardContent>
      </Card>
    )}
  </Grid>
);
const WizardAction = (props) => (
  <Grid
    container
    item
    xs={12}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    className="margin-y-20"
  >
    <Grid item xs={6}>
      <Button
        variant="contained"
        disabled={props.activeStep === 0}
        onClick={props.handleBack}
      >
        <KeyboardArrowLeft /> Back
      </Button>
    </Grid>
    <Grid
      container
      item
      xs ={6}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {props.activeStep === props.steps.length - 1 ? (
        <Button
          className="pull-right"
          variant="contained"
          color="primary"
          disabled={props.finishStatus}
          onClick={props.handleFinish}
        >
          Finish &nbsp;
           { !props.finishStatus ? "" :(<CircularProgress size={17} color="inherit" />)}
        </Button>
      ) : (
        <Button
          className="pull-right"
          variant="contained"
          color="primary"
          onClick={props.handleNext}
        >
          Next <KeyboardArrowRight />
        </Button>
      )}
    </Grid>
  </Grid>
);

const PreAssessmentWizard = (props) => {
  const activeStep = props.paa ? props.paa.lastCompletedStep : -1;
  const dispatch = useDispatch();
  const [steps, setSteps] = useState();
  const [finishStatus, setFinishStatus] = useState(false);

  useEffect(() => {
    dispatch(hideAlert());
    if (!props.paa) {
      //on refresh, the paa state will become null.
      //handle refresh scenario, to get the last saved state from DB
      dispatch(showProgress());
      dispatch(getCurrentPAASession()).then((res) => {
        dispatch(hideProgress());
      });
    }
    setSteps(getSteps());
  }, []);

  const handleNext = (e) => {
    dispatch(showProgress());
    dispatch(goToNextStep(props.paa));
    //dispatch(hideProgress());
  };

  const handleFinish = (e) => {
    dispatch(showProgress());
    setFinishStatus(true);
    dispatch(finishPAASession(props.paa));
    // dispatch(hideProgress());
  };

  const handleBack = () => {
    dispatch(showProgress());
    dispatch(goToPreviousStep(props.paa));
    dispatch(hideProgress());
  };

  const handlePrint = () => {
    dispatch(showProgress());
    dispatch(printResources(props.paa)).then(link => {
        dispatch(hideProgress());
        window.open(link, "_blank");
    });
  };

  return (activeStep > -1 && steps) ? (
    <Grid container >
      <WizardTitle />
      <WizardStepper activeStep={activeStep} steps={steps} />
      <WizardContent activeStep={activeStep} steps={steps} />
      {activeStep < steps.length ? (
        <WizardAction
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          handleFinish={handleFinish}
          handlePrint={handlePrint}
          finishStatus = {finishStatus}
        />
      ) : (
        ""
      )}
    </Grid>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({
  paa: state.paa,
});
export default connect(mapStateToProps)(PreAssessmentWizard);
