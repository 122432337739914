import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import './i18n';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Loader from "./Loader";

let sessionState = { };
if (sessionStorage.getItem('userSession')) {
  sessionState=JSON.parse(sessionStorage.getItem('userSession'))
}
ReactDOM.render(
  <Provider store={configureStore(sessionState)}>
    <Suspense fallback={(<Loader />)}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();

