import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { HighlightOffRounded } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { showAlert, hideAlert } from "../../redux/alertActions";
import { approveOrRejectCp } from "../../redux/importActions";
import CloudOffRoundedIcon from '@material-ui/icons/CloudOffRounded';

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: "#fedb00",
        color: "#1e1e1e",
    }
}));
export default function RejectionDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [rejectDesc, setRejectDesc] = React.useState(null);
    const { t } = useTranslation();
    const formRef = useRef();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setRejectDesc(null);
        setOpen(false);
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setRejectDesc(value);
    };

    const handleSubmit = (newCpStatus, currentCpId, cptestDescription) => {
        dispatch(hideAlert());
        let cpData = {
            cpUploadedTestStatus: newCpStatus,
            cpId: currentCpId,
            cpRejectDesc: rejectDesc
        };
        dispatch(approveOrRejectCp(cpData)).then(res => {
            if (res === "Success") {
                dispatch(showAlert("Content Package " + cptestDescription + " is Rejected", "success"));
                handleClose();
                props.showButtons(newCpStatus);
            } else {
                dispatch(showAlert("Content Package " + cptestDescription + " rejection process failed", "error"));
                handleClose();
            }
        });
    };

    return (
        <React.Fragment>
            <Tooltip title="Reject" aria-label="Reject">
                <Button variant="contained"
                    color="inherit"
                    className={props.classes.buttonWhite}
                    startIcon={<HighlightOffRounded color="secondary" />}
                    onClick={handleClickOpen}
                >
                    Reject
            </Button>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle className="dialog-title" id="form-dialog-title">
                    <span>
                        <CloudOffRoundedIcon style={{ position: "relative", top: "5px", marginRight: "5px" }} />
                        {t('rejectCp.title')}
                    </span>
                    <span style={{ float: 'right' }}>
                        <HighlightOffRounded onClick={handleClose} style={{ color: 'black', cursor: 'pointer' }} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <ValidatorForm ref={formRef} onSubmit={() => handleSubmit(6, props.cpData.id, props.cpData.testDescription)} noValidate >
                        <DialogContentText>
                            {t('rejectCp.content')}
                        </DialogContentText>
                        <TextValidator
                            autoFocus
                            margin="dense"
                            name="rejectDesc"
                            type="text"
                            required
                            validators={["required"]}
                            errorMessages={["Reason is required"]}
                            id="rejectDesc"
                            onChange={handleInputChange}
                            value={rejectDesc || ""}
                            fullWidth
                        />
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" className={classes.button} >
                                Submit
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
