import axios from 'axios';


const axiosInstance = axios.create({
});
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status === 401) {
    sessionStorage.clear();
    window.location.href = "/login"
  }
  return Promise.reject(error);
});

axiosInstance.interceptors.request.use(function (request) {
  if (sessionStorage.getItem('userSession')) {
    const userLoggedInTime = JSON.parse(sessionStorage.getItem('userSession')).login.data.loginTime;
    request.headers["userLoggedInTime"] = userLoggedInTime;
  }
  return request;
});

export default axiosInstance;