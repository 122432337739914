import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { connect } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PAACompletion = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title={
          <h3 className="cb-item-title text-center margin-bottom-0">
            Congratulations! You have completed your TSIA2 Pre-Assessment
            Activity!
          </h3>
        }
      ></CardHeader>
      <CardContent className="col-xs-12">
        <Link href={props.paa.certificateURL} target="_blank">
          <p>Click here to view/download your completion certificate.</p>
        </Link>
        <div>
          <h3>Next Steps:</h3>
          {/* <p className='cb-item-des'><strong>{row.testCategoryName}</strong></p> */}
          <ul>
            <li>
              <RouterLink to={"/home/test/4"}>
                Click here to practice more TSIA2 test.
              </RouterLink>
            </li>
            <li>
              <Link href="https://accuplacer.collegeboard.org/accuplacer/pdf/tsia2-student-informational-brochure.pdf" target="_blank">
                Click here to download the Student Informational Brochure.
              </Link>
            </li>
            <li>Check your email for PAA Completion Certificate.</li>
            <li>Print your PAA Completion Certificate.</li>
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
    paa: state.paa,
  });
  export default connect(mapStateToProps)(PAACompletion);
