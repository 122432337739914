import C from "./constants"
import uuid from 'node-uuid';
export default (state = { id:null,showAlert: false,
	  style: "", 
	  heading: "",
	  msg: "" }, action) => {
    switch (action.type) {
      case C.ADD_ALERT:
      return (
        {
			showAlert: true,
          style: action.style ? action.style : "success",
          heading: action.heading ? action.heading : "Success",
			msg:action.msg,
          id: uuid()
        });
     

    case C.REMOVE_ALERT:
      return (
        {
			showAlert: false,
          style: "",
          heading: "",
			msg:"",
          id: ""
        });
		default:
            return {
				...state
			};
    }
};