import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {  Card, CardContent,Tooltip, Fab} from "@material-ui/core";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'None',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  divMarginBottomStyle :{
    marginBottom:'100px'
  },
  paperStyle :{
    padding: '20px 0 24px 0',
    border: '1px solid transparent',
    width:'100%'
  },
  headerStyle:{
    fontFamily: ' inherit !important',
    color: '#2d2d2d'
  }, 
  card: {
    marginTop:'20px',
    textAlign: "left",
    marginBottom: "20px",
    boxShadow:'0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
  },
  mainDiv:{
    marginLeft: '80px',
    marginRight: '80px'
  },
  viewBtn: {
	  backgroundColor: '#fedb00',
	    color: '#1e1e1e',
	    marginRight: "4px",
	  },
  container: {
	  borderBottom: "solid 1px #ccc",
	  marginBottom: "10px"
  }
}))

const AboutThisApp = (props) => {
  console.log("AboutThisApp props" , props);
  const classes = useStyles()
  return (
      <div maxWidth="md" className ={ classes.divMarginBottomStyle} style ={props.userData && props.userData.isloggedIn ? {marginTop:'100px'} : null}>
            <div className={classes.mainDiv}>
                <Card className={classes.card} variant="outlined">
                  <CardContent>
                 <Paper className={classes.paper} >
                    <div className={ classes.paperStyle}>
                    <Grid container className={classes.container}>
                    <Grid item className="col-xs-12 col-sm-11 no-padding">
                    <div className="col-xs-12"><h2 className = {classes.headerStyle}>About ACCUPLACER</h2></div>
                    </Grid>
                    <Grid item className="col-xs-12 col-sm-1 no-padding" style={{textAlign: 'right',marginBottom:'5px'}} justifyContent="right" alignContent="right">
                    <Tooltip title="Close" aria-label="Close">
                    <Fab
                      className={classes.viewBtn}
                      size="small"
                	  onClick={() => props.history.goBack()}
                    >
                    <CloseIcon />
                    </Fab>
                  </Tooltip>
                  </Grid>
                  </Grid>
                    <div className="col-xs-12">
                      <p>The ACCUPLACER Web App has been developed by 
                        the College Board and is intended to familiarize you with the basic content 
                        areas of the ACCUPLACER test. This App is not designed to serve as a 
                        substitute for an actual ACCUPLACER test, nor does it guarantee that you
                         will receive a specific score on an actual ACCUPLACER test.</p>     
                      </div>
                    </div>
                  </Paper>
                  <Paper className={classes.paper} >
                    <div className={classes.paperStyle}>
                    <Grid container className={classes.container}>
                    <Grid item className="col-xs-12 col-sm-12 no-padding">
                    <div className="col-xs-12"><h2 className = {classes.headerStyle}>Terms and Conditions</h2></div>
                    </Grid>
                    </Grid>
                    
                    <div className="col-xs-12">  <p>The ACCUPLACER Web App is designed to familiarize students with the style and 
                        content of test questions found on the ACCUPLACER tests. It does not represent an actual 
                        ACCUPLACER test and is intended for practice and review only. Results are not 
                        intended to be used to determine course placement. Test results on the ACCUPLACER 
                        Web App are not intended to suggest similar performance, nor do they have any correlation to scores 
                        from tests administered on the ACCUPLACER platform.</p>
                        <p>&copy; {new Date().getFullYear()} The College Board. All rights reserved. College Board, 
                          ACCUPLACER, and the acorn logo are registered trademarks of the 
                          College Board. Connect to college success is a trademark owned by 
                          the College Board. All other products and services may be trademarks of 
                          their respective owners.</p>
                          <p>
                              Visit the College Board on the Web: 
                              <a target="_blank" rel="noopener noreferrer" href="https://www.collegeboard.org/">
                                www.collegeboard.org</a></p>
                        </div> 
                    </div>
                  </Paper>
                  </CardContent>
                  </Card>
            </div>
      </div>
  )
}

const mapStateToProps = state => ({
  userData: state.login
});

export default  connect(mapStateToProps)(AboutThisApp);
