import ActionTypes from "../constants/ActionTypes";

export default (state = null, action) => {
  switch (action.type) {
    case ActionTypes.GET_PAA:
      return state
    case ActionTypes.UPDATE_PAA:
      return action.data;
    default:
      return state;
  }
};
