import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getTexasInstitutions } from "../../redux/preAssessmentActions";
import ActionTypes from "../../constants/ActionTypes";
import { hideAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from "@date-io/date-fns";
import {  KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import { useDispatch, connect } from "react-redux";
import { Grid } from "@material-ui/core";
import moment from 'moment'; 
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

const populateInstitution = (txInstitutionList, institutionIdPk) => {
  let institution;
  if (txInstitutionList && institutionIdPk) {
    institution = txInstitutionList.find(
      (i) => institutionIdPk === i.institutionIdPk
    );
  }
  return institution || "";
};

const PAAStep1 = (props) => {
  const dispatch = useDispatch();
  const agreement = props.paa ? props.paa.agreementChecked : undefined;
  const [txInstitutionList, setTxInstitutionList] = useState([]);
  const institution = props.paa && props.paa.institutionId
      ? populateInstitution(txInstitutionList, props.paa.institutionId)
      : "";
  const [selectedDate, setSelectedDate] = useState(null);
  const [studentDisplayId, setStudentDisplayId] = useState("");
  const dobValid = props.paa && props.paa.studentDOB === null ? props.paa.studentDOB  : undefined;
  const today = new Date();

  useEffect(() => {
    dispatch(hideAlert());
    dispatch(showProgress());
    dispatch(getTexasInstitutions()).then((res) => {
      setTxInstitutionList(res.texasInstitutions);
      dispatch(hideProgress());
    });
    setSelectedDate(props.paa && props.paa.studentDOB? props.paa.studentDOB :null);
    setStudentDisplayId(props.paa && props.paa.studentDisplayId? props.paa.studentDisplayId : '');
    return () => {
      setTxInstitutionList([]);
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const renderAutoCompleteInput = (params) => {
    const showError = props.paa && props.paa.institutionId === null;
    const errorMsg = showError ? "Instituion selection is required." : "";
    return (
      <TextField
        {...params}
        label="Please select the campus where you plan to take the TSIA2."
        variant="outlined"
        required
        error={showError}
        helperText={errorMsg}
      />
    );
  };

  function handleInstitutionChange(event, newValue) {
    const newInstituionValue = newValue ? newValue.institutionIdPk : null;
    dispatch({
      type: ActionTypes.UPDATE_PAA,
      data: {
        ...props.paa,
        institution: newValue,
        institutionId: newInstituionValue,
      },
    });
  }
  const handleAgreementChange = (event) => {
    dispatch({
      type: ActionTypes.UPDATE_PAA,
      data: { ...props.paa, agreementChecked: event.target.checked },
    });
  };

  const handleStudentDisplayId = (value) => {
    const newId = value ? value : "";
    setStudentDisplayId(newId);
    dispatch({
      type: ActionTypes.UPDATE_PAA,
      data: {
        ...props.paa,
        studentDisplayId: newId,
      },
    });
  };

  const handleDateChange = (date) => {
    const selectedDOB= date ? moment(date).format('MMM D, YYYY hh:mm:ss a') :"";
    let currentYear = today.getFullYear();
    let selectedYear = date && date != null ? date.getFullYear() : null;
    if ( selectedYear != null && selectedYear < currentYear && selectedYear > 1900 ) {
      setSelectedDate(selectedDOB);
      dispatch({
        type: ActionTypes.UPDATE_PAA,
        data: {
          ...props.paa,
          studentDOB: selectedDOB,
        },
      });
    } else {
      setSelectedDate("");
      dispatch({
        type: ActionTypes.UPDATE_PAA,
        data: {
          ...props.paa,
          studentDOB: "",
        },
      });
    }
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="institutionIdAutoComplete"
            className="margin-bottom-20"
            fullWidth
            clearOnEscape
            value={institution}
            onChange={handleInstitutionChange}
            options={txInstitutionList}
            getOptionLabel={(option) => (option ? option.institutionName : "")}
            renderInput={renderAutoCompleteInput}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="studentDisplayId"
            label="Student ID"
            variant="outlined"
            value={studentDisplayId}
            fullWidth
            onChange={(e) => handleStudentDisplayId(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Please use the ID provided to you by the institution. If you do not have one, you may leave this blank">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              clearable
              disableFuture
              fullWidth
              openTo="year"
              required
              autoOk
              invalidDateMessage="Valid date of birth is required"
              placeholder="MM/DD/YYYY"
              format="MM/dd/yyyy"
              label="Date of birth"
              value={selectedDate}
              onChange={handleDateChange}
              inputVariant="outlined"
              maxDate={today.setDate(today.getDate() - 1)}
              maxDateMessage = "Valid date of birth is required"
              minDateMessage = "Valid date of birth is required"
              error={(dobValid === null || (selectedDate === "Invalid date" || selectedDate === "" ))? true:false}
            />
          </MuiPickersUtilsProvider>
            {(dobValid === null && selectedDate !="Invalid date" && selectedDate !="") ? (
              <Typography className="MuiFormHelperText-contained Mui-error" 
              style={{marginTop: '3px'}} variant="caption">Valid date of birth is required</Typography>
            ) : (
              ""
            )}
        </Grid>

        <Grid item xs={12} className="margin-top-5">
          <FormControlLabel
            control={
              <Checkbox
                name="agreementCheckbox"
                checked={!!agreement}
                value={agreement}
                onChange={handleAgreementChange}
                color="primary"
                required
              />
            }
            label={
              <React.Fragment>
                <Typography className={agreement === false ? "Mui-error" : ""}>
                  I agree to allow ACCUPLACER to share my name, date of birth,
                  email address, and Pre-Assessment Activity completion status
                  with the selected institution.
                </Typography>
              </React.Fragment>
            }
          />
          {agreement === false ? (
            <FormHelperText error>
              Please accept the agreement by checking the checkbox above.
            </FormHelperText>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  paa: state.paa,
});
export default connect(mapStateToProps)(PAAStep1);
