import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { compose } from "redux"
import { connect } from "react-redux"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { showAlert, hideAlert } from '../../redux/alertActions'
import { getProfile, saveProfile } from '../../redux/userActions'
import Container from "@material-ui/core/Container";
import { updateUserName } from '../../redux/auth/action';
import {  TextValidator,ValidatorForm } from "react-material-ui-form-validator";
import { Fab } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  typography: {
    spacing: 4,
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    textAlign: "center"
  },
  card: {
    minWidth: 300,
    padding: theme.spacing(3),
    borderShadow: 'none'
  },
  ulStyle: {
    justifyContent: "center"
  },
  margin: {
    margin: theme.spacing(1)
  },
  noPadding: {
    padding: "0px"
  },
  displayAnchorCenter: {
    display: "block",
    textAlign: "center"
  }, 
  floatIcon: {
    position: "absolute",
    margin: "-10px 5px"
  },
  floatEditIcon: {
    float: "left",
    marginTop: "15px"
  },
  doneBtn: {
    backgroundColor: "#fedb00",
    color: "#1e1e1e",
    float: "left",
    marginTop: "15px"
  },
})
class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: props.loggedInUser.userId,
      firstName: props.loggedInUser.firstName,
      lastName: props.loggedInUser.lastName,
      email: props.loggedInUser.userName,
      roleName: props.loggedInUser.SES_ROLE_NAME,
      since: props.loggedInUser.fromAccountDate,
      changeName: false
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.submitChange = this.submitChange.bind(this);
    this.formRef = React.createRef();
  }
  toggleEdit(e) {
    console.log(this.state)
    if (this.state.changeName) {
      this.setState({ changeName: false });
    } else {
      this.setState({ changeName: true });
    }
    this.setState({
      firstName: this.props.loggedInUser.firstName,
      lastName: this.props.loggedInUser.lastName,
    })
    console.log(this.state)
  }
  submitChange(e) {
    this.props.hideAlert()
    let { userId, firstName, lastName } = this.state;
    firstName = firstName.trim();
    lastName = lastName.trim();
    if (userId && firstName && lastName) {
      this.props.updateUserName({ firstName, lastName })
      this.props.saveProfile(this.state).then(response => {
        if (response && response.data && response.data.result === "SUCCESS") {
          this.props.updateUserName({ firstName, lastName })
          this.props.showAlert("Profile updated successfully", "success")
          this.setState({ changeName: false });
        };
        if (response && response.data && response.data.result === "FAILED") {
          this.props.showAlert("Error saving profile information", "error")
        }
      });
    }
  }

  componentDidMount() {
    this.props.hideAlert()
  }
  componentWillUnmount(){
    this.props.hideAlert()
  }
  render() {

    const { classes } = this.props;
    const divStyle = {
      marginTop: "2em",
      marginBottom: "4em"
    };
    return (
      <Container maxWidth="md" style={divStyle}>
        <div className="row">
          <Paper className="paper-box-container">
            <div className=" hDivider"></div>
            <Grid container role="region" aria-label="profile" >
              <Grid item xs={12}>
                <Container component="main" maxWidth="lg">
                  <div className={classes.paper}>
                    <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <Card className="no-box">

                        <CardContent>
                          <span className="cb-glyph-multi cb-glyph-lg cb-user cb-glyph-higher-education"></span>
                          {this.state.changeName === false &&
                            <div className="col-sm-12">
                              <Typography gutterBottom variant="h5" component="h2" align="center">
                                {this.props.loggedInUser.firstName} {this.props.loggedInUser.lastName} 
                                <Fab   size="small" style={{marginLeft:'10px'}}   aria-label="settings" onClick={this.toggleEdit}>
                                  <EditIcon />
                                </Fab>
                              </Typography>
                            </div>
                          }
                          {this.state.changeName === true &&
                            <div className="col-xs-12 col-sm-offset-3 col-sm-9">
                               <ValidatorForm ref={this.formRef} onSubmit={this.submitChange} noValidate>
                              <TextValidator
                                className={`${classes.margin} col-xs-12 col-sm-offset-4 col-sm-4`}
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                required
                                validators={["required"]}
                                errorMessages={[
                                  "First Name is required",
                                ]}
                                label="First Name"
                                id="firstName"
                                value={this.state.firstName}
                                onChange={e => this.setState({ firstName: e.target.value })}

                              />
                               <TextValidator
                                className={`${classes.margin} col-xs-12 col-sm-4`}
                                type="text"
                                required
                                inputProps={{ maxLength: 100 }}
                                validators={["required"]}
                                errorMessages={[
                                  "Last Name is required",
                                ]}
                                label="Last Name"
                                id="lastName"
                                value={this.state.lastName}
                                onChange={e => this.setState({ lastName: e.target.value })}
                              />
                                <Fab  size="small"  aria-label="submit button" type="submit" style={{marginRight:'5px'}} className={`${classes.doneBtn} hidden-xs`}>
                                  <DoneIcon />
                                </Fab>
                                <Fab   size="small"  aria-label="close button" onClick={this.toggleEdit} className={`${classes.floatEditIcon} hidden-xs`}>
                                  <ClearIcon/>
                                </Fab>

                                <Fab   size="small" aria-label="submit button"  style={{marginRight:'5px'}} type="submit" className={`${classes.doneBtn} hidden-lg hidden-sm hidden-md`}>
                                  <DoneIcon />
                                </Fab>
                                <Fab   size="small"  aria-label="close button" onClick={this.toggleEdit} className="hidden-lg hidden-sm hidden-md">
                                  <ClearIcon  />
                                </Fab>
                              
                              </ValidatorForm>
                              <br/>

                            </div>
                          }
                          <div className="col-sm-offset-2 col-sm-10 col-xs-12">
                            <Typography variant="body1" color="textSecondary" component="p" align="right" className={` ${classes.noPadding} col-xs-4`}>
                              Role: 
                            </Typography>
                            <Typography variant="body1" color="textSecondary" component="p" align="left" className="col-xs-8">
                              {this.state.roleName}
                            </Typography>
                          </div>
                          <div className="col-sm-offset-2 col-sm-10 col-xs-12">
                            <Typography variant="body1" color="textSecondary" component="p" align="right" className={` ${classes.noPadding} col-xs-4`}>
                              Email:
                            </Typography>
                            <Typography variant="body1" color="textSecondary" component="p" align="left" className="col-xs-8" style ={{maxHeight: 200, overflow: 'auto'}}>
                              {this.state.email}
                            </Typography>
                          </div>
                          <div className="col-sm-offset-2 col-sm-10 col-xs-12">
                            <Typography variant="body1" color="textSecondary" component="p" align="right" className={` ${classes.noPadding} col-xs-4`}>
                              User since:
                            </Typography>
                            <Typography variant="body1" color="textSecondary" component="p" align="left" className="col-xs-8">
                              {new Intl.DateTimeFormat('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit'
                              }).format(this.state.since)}
                            </Typography>
                          </div>                          
                        </CardContent>
                        <CardActions className={` ${classes.displayAnchorCenter} col-xs-12`}>

                          <Button size="small" color="primary" component={Link} to="/home/changepassword">
                            Change Password
                          </Button>
                        </CardActions>
                      </Card>


                    </Grid>
                  </div>
                </Container>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    )
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  loggedInUser: state.login.data
});
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile()),
  saveProfile: user => dispatch(saveProfile(user)),
  showAlert: (msg, style, heading) => dispatch(showAlert(msg, style, heading)),
  hideAlert: () => dispatch(hideAlert()),
  updateUserName: (user) => dispatch(updateUserName(user)),
});
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles(styles)
)(Profile)