import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PAAStep5 = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="row " justifyContent="flex-start">
      <div className="cb-type-article noTitleBar" key="preassessment">
        <div className="cb-item-text">
          <h2 className="cb-item-title"><u>Tips for Success for TSIA2 Testing</u></h2>
          <p className="cb-item-des">
          Testing can be stressful. Note the following tips as you prepare to test:
          </p>
          <ul className="cb-item-list">
          <li>Relax and think positively – You will do better!</li>
          <li>Get a good night‘s rest the night before your testing session.</li>
          <li>Eat a healthy meal prior to your testing session.</li>
          <li>Review the tutorials for the on-line calculator, highlighter, and the accommodation wizard tools prior to testing. As a reminder, handheld calculators of any type are not allowed.</li>
          <li>If you have a physical or learning disability and need testing accommodations, please contact the Office of Disability Services at your institution.
           High school students should contact their counselor or testing coordinator.</li>
          </ul>
        </div>
        <div className="cb-item-text">
          <h2 className="cb-item-title"><u>Resources for Success: TSIA2 Student Portal</u></h2>
          <p className="cb-item-des">The <Link href="https://studentportal.accuplacer.org/" target="_blank"><u>TSIA2 Student Portal</u></Link> gives you access to the following:</p>
          <ul className="cb-item-list">
          <li><Link href ="https://accuplacer.collegeboard.org/students/prepare-for-accuplacer/tsia-texas-success-initiative-assessment" target="_blank"><u>FREE practice materials</u> </Link>for the TSIA2.
            You can practice using the TSIA2 Study App on any smartphone or tablet and get immediate feedback on your performance, or with printable PDF sample questions.</li>
          <li>Tutorials showing you how to use the calculator, highlighter, and the accommodation wizard tools.</li>
          <li>Your test results and how to send your test results to Texas public institutions of higher-education.</li>
          </ul>
        </div>
        <div className="cb-item-text">
          <h2 className="cb-item-title"><u>Additional TSIA2 Learning Resources</u></h2>
          <ul className="cb-item-list">
            <li>TSIA2 Learning Resources is an online library of instructional and practice materials aligned to the content assessed on the TSIA2.</li>
            <li>Use these resources prior to the first time you take TSIA2 or prior to retesting. There are videos, interactive worksheets, and other materials available to help you prepare for the test. </li>
            <li>To access TSIA2 Learning Resources <Link href="https://tsia2.pearsonperspective.com/perspective/" target="_blank"><u>click here</u></Link> and click on "Enter Student Site". </li>
          </ul>
        </div>
        <div className="cb-item-text">
          <h2 className="cb-item-title"><u>Institutional Resources</u></h2>
          <p className="cb-item-des"><b>Academic Advisor</b>. Contact your academic advisor for assistance in interpreting your TSIA2 scores,
          enrolling in the appropriate courses, or to discuss retesting options (if necessary).</p>
          <p className="cb-item-des"><b>Disability Services Office (DSO)</b>. Contact the DSO at your institution to request accommodations other than those built into the platform and available to all testers.
          Reference the TSIA2 Accommodations Guidelines <Link href="https://reportcenter.highered.texas.gov/training-materials/tsia2-accommodations-guidelines/" target="_blank"><u>here</u></Link>. </p>
         <p className=" cb-item-des"><b>Learning Labs and Tutoring Services</b>. Most institutions (high school and higher education) have learning labs and/or tutoring services available FREE of charge to its students.
         These services can be helpful in addressing skill gaps you may have before you test.</p>
         <p className=" cb-item-des"><b>Testing Center</b>. Contact the testing center at your institution for more specific information on TSIA2 testing options, costs, and availability.</p>
        </div>
      </div>
    </Grid>
  );
};
export default PAAStep5;
