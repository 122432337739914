import C from "../../redux/constants"
import axiosInstance from "../../services/API";

export function getGoogleAppId() {
  return function (dispatch) {
    return axiosInstance
      .get("/api/getGoogleAppId")
      .then(response => {
        return response.data;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function submitLogin(userObj) {
  return function (dispatch) {
    return axiosInstance
      .post("/api/login", userObj)
      .then(response => {
        return response.data;
      })
      .catch(response => {
        console.log(response);
      });
  };
}


export function aclAction(submitRes) {
  return function (dispatch) {
    return axiosInstance
      .post("/api/getCore", submitRes)
      .then(response => {
        dispatch({
          type: C.LOGIN_SUCCESSFULL,
          data: submitRes
        });
        dispatch({
          type: C.ACL_SUCCESSFUL,
          data: response.data,
        });
        return response.data;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function signUp(registerUserObj) {
  return function (dispatch) {
    return axiosInstance
      .post("/api/signup", registerUserObj)
      .then(response => {
        return response;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function logoutAction() {
  return function (dispatch) {
    return axiosInstance
      .post("/api/logout")
      .then(response => {
        dispatch({
          type: C.LOGOUT_SUCCESSFUL,
          data: response.data,
        });
        sessionStorage.clear();
        return response.data;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export const submitForgot = (userObj, history) => async dispatch => {
  try {
    const { data } = await axiosInstance.post('/api/forgotpassword', userObj)
    dispatch({ type: C.FORGOT_SUCCESSFUL, payload: data })
    return data;
  } catch (e) {
    console.log(e)
  }
}

export const enterResetPassword = (userObj, history) => async dispatch => {
  try {
    const { data } = await axiosInstance.post('/api/enterresetpassword', userObj)
    dispatch({ type: C.RESET_PASSWORD_ENTER, payload: data })
    return data;
  } catch (e) {
    console.log(e)
  }
}

export function submitResetPassword(userObj) {
  console.log(userObj);
  return function (dispatch) {
    return axiosInstance
      .post("/api/resetpassword", userObj)
      .then(response => {
        dispatch({
          type: C.RESET_PASSWORD_SUCCESSFUL,
          data: response.data,
          email: userObj.email
        });
        return response.data;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function inviteUsersSendMail(userObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/inviteUsers", userObj)
      .then(resp => {
        return resp.data;
      })
  };
}

export function processNewUsrLink(userObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/processInviteUserLink", userObj)
      .then(resp => {
        return resp.data;
      })
  };
}

export function startTest(testObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/getTokenForData/" + testObj.path, testObj)
      .then(resp => {
        return resp.data;
      })
  };
}
export function updateUserName(payload) {
  return {
    type: C.UPDATE_USER_NAME,
    data: payload
  };
}


export function getTestVersion() {
  return function (dispatch) {
    return axiosInstance.post("/api/getTestVersion")
      .then(resp => {
        return resp.data;
      })
  };
}

export function getTestVersionForImport() {
  return function (dispatch) {
    return axiosInstance.post("/api/getTestVersionForImport")
      .then(resp => {
        return resp.data;
      })
  };
}

export function getAppVersion() {
  return function (dispatch) {
    return axiosInstance.get("/api/getAppVersion")
      .then(resp => {
        return resp.data;
      })
  };
}