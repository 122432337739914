import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  HIDE_MODAL: null,
  SHOW_MODAL: null,
  STORE_WINDOW: null,
  GET_WINDOW: null,
  GET_PAA: null,
  UPDATE_PAA: null
});

export default ActionTypes;
