import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { Link} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar'
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { getAppVersion } from "../redux/auth/action";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: "0",
    borderTop: '4px solid #fedb00',
    background: '#002039',
    color: 'white',
    fontSize: '0.85rem',
    lineHeight: "1.5em"
  },
  grow: {
    flexGrow: 1,
  },
  footerLink: {
    color: 'white',
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "-5px"
  },
  iconStyle: {
	  marginBottom: '-4px',
	  fontSize: "1.0rem"
  }
})

class Footer extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	version: "1.0"
	    }
	  }
	componentDidMount() {
		this.props.getAppVersion().then(response => {
	        if (response  ) {
	        	this.setState({ version: response });
	        }
		});
	}
	getYear() {
	    return new Date().getFullYear();
	}
	
	render() {
	    const { classes } = this.props;
	    return (<AppBar id="footerComponent" className={classes.appBar} component="footer" role="contentinfo">
	    <div className="col-xs-12" style={{textAlign: 'center',marginTop:"5px"}}>
		  <span>&copy; {this.getYear()} College Board </span> <MoreVertOutlinedIcon className={classes.iconStyle}></MoreVertOutlinedIcon> <span>version: {this.state.version}</span>
		  </div>
		  <div className="col-xs-12" style={{textAlign: 'center',marginBottom:"10px"}}>
		  
		  <Link className={classes.footerLink} to={{pathname: "/aboutThisApp"} } >About this App</Link>
		  <MoreVertOutlinedIcon className={classes.iconStyle}></MoreVertOutlinedIcon>
		  <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://pages.collegeboard.org/contact-us?navId=gf-contact" >
		  Contact Us
	      </a>
		  <MoreVertOutlinedIcon className={classes.iconStyle}></MoreVertOutlinedIcon>
		  <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://about.collegeboard.org/privacy-policy/privacy-statement?navId=gf-pp&navId=gf-pp" >
	        Privacy Policy
	      </a>
	        <MoreVertOutlinedIcon className={classes.iconStyle}></MoreVertOutlinedIcon>
		  <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://about.collegeboard.org/site-terms?navId=gf-terms" >
		  Terms of Use
	      </a>
		  <MoreVertOutlinedIcon className={classes.iconStyle}></MoreVertOutlinedIcon>
		  <a className={classes.footerLink} target="_blank" rel="noopener noreferrer" href="https://accuplacer.collegeboard.org/students/before-test-day/test-day" >
		  FAQs
	      </a>
	    </div>
	  </AppBar>
	  )
	}
}
Footer.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapDispatchToProps = dispatch => ({
	getAppVersion: () => dispatch(getAppVersion())
});
const mapStateToProps = state => ({
  ...state
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Footer);