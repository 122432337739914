import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { showAlert, hideAlert } from "../../redux/alertActions";
import { getProfile, changePassword } from "../../redux/userActions";
import { PasswordValidationRegex } from "../../shared/Validation";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import { logoutAction } from "../../redux/auth/action";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  leftpaper: {
    marginTop: theme.spacing(0),
    textAlign: "left",
    padding: "10px",
    minHeight: "390px",
    height: "100%"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(0)
  },
  card: {
    minWidth: 300,
    padding: theme.spacing(3)
  },
  ulStyle: {
    justifyContent: "center"
  },
  margin: {
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    backgroundColor: "#fedb00",
    color: "#1e1e1e"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  infoAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
});
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.loggedInUser.userName,
      current: "",
      password: "",
      confirmpassword: "",
      userId: props.loggedInUser.userId,
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitChange = this.submitChange.bind(this);
  }
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  submitChange(e) {
    e.preventDefault();
    this.props.hideAlert();
    this.setState({ submitted: true });
    const {  current, password, confirmpassword, userId } = this.state;
    if ( current && password && confirmpassword && userId) {
      this.props.changePassword(this.state).then(response => {
        if (response && response.data && response.data.result === "SUCCESS") {
          this.onResetClick();
          if (this.props.stateWindow.windowObj && this.props.stateWindow.windowObj.w) {
            this.props.stateWindow.windowObj.w.close();      
          }
          this.props.logoutAction().then((res) => {
            this.props.history.push("/");
            this.props.showAlert(
              "Your password has been updated. Please use your new password for login.",
              "success"
            );
          });
        }
        if (response && response.data && response.data.result === "FAILED") {
          this.props.showAlert(response.data.msg, "error");
        }
      });
    }
  }
  onResetClick = () => {
    this.setState({
      current: "",
      password: "",
      confirmpassword: ""
    });
  }
  componentDidMount() {
    this.props.hideAlert();
    console.log(this.props);
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value && value !== "" && value !== this.state.password) {
        return false;
      }
      return true;
    });
    // custom rule for password validation
    ValidatorForm.addValidationRule(
      "matchPasswordCriteria",
      PasswordValidationRegex
    );
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
    this.props.hideAlert();
  }
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const divStyle = {
      marginTop: "2em",
      marginBottom: "4em"
    };
    return (
      <Container maxWidth="md" style={divStyle}>
        <div className="row">
          <Paper className={` paper-box-container`}>
            <div className=" hDivider"></div>
            <Grid container>
              <Grid item xs={12}>
                <Container component="main" maxWidth="lg">
                  <div className={`${classes.paper} col-xs-12`}>
                    <Paper className={` ${classes.leftpaper} col-xs-12 col-sm-6`}>
                                     
                        <Avatar className={`${classes.infoAvatar} margin-center`}>
                          <InfoRounded />
                        </Avatar>
                        <Typography align="center" color="primary" component="h1" variant="h5">
                          {t("resetPassword.information")}
                        </Typography>
                        <br />
                        <Typography component="p">
                          {t("resetPassword.passwordLength")}
                        </Typography>
                        <Typography component="div">                          
                          <ul className="passCriteriaUl">
                            <li>{t("resetPassword.lowercase")}</li>
                            <li>{t("resetPassword.numeral")}</li>
                            <li>{t("resetPassword.uppercase")}</li>
                            <li>{t("resetPassword.specialCharacter")}</li>
                          </ul>
                        </Typography>
                      
                    </Paper>
                    <div className="col-xs-12 col-sm-6">
                      <div className={`${classes.paper} col-xs-12`}>
                        <Avatar className={`${classes.avatar} margin-center`}>
                          <LockOutlinedIcon />
                        </Avatar>
                        <Typography align="center" component="h1" variant="h5">
                          {t("changePassword.header")}
                        </Typography>
                        <ValidatorForm
                          className={classes.form}
                          noValidate
                          ref="form"
                          onSubmit={this.submitChange}
                          onError={errors => console.log(errors)}
                          autocomplete="off"
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="current"
                                label="Current Password"
                                type="password"
                                id="currentpassword"
                                validators={[
                                  "required",
                                  "matchPasswordCriteria"
                                ]}
                                errorMessages={[
                                  "Current Password is required.",
                                  "Password must follow the criteria."
                                ]}
                                value={this.state.current}
                                inputProps={{ maxLength: 50 }}
                                onChange={this.handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="New Password"
                                type="password"
                                id="password"
                                validators={[
                                  "required",
                                  "matchPasswordCriteria"
                                ]}
                                errorMessages={[
                                  "New Password is required.",
                                  "Password must follow the criteria."
                                ]}
                                value={this.state.password}
                                inputProps={{ maxLength: 50 }}
                                onChange={this.handleChange}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="confirmpassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmpassword"
                                validators={["isPasswordMatch", "required"]}
                                errorMessages={[
                                  "Passwords typed do not match. Please re-enter your password.",
                                  "Confirm Password is required."
                                ]}
                                value={this.state.confirmpassword}
                                inputProps={{ maxLength: 50 }}
                                onChange={this.handleChange}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}  className="change-password-btn">
                            <Grid item xs={4}  sm={5}>
                              <button
                                type="button"
                                onClick={this.onResetClick}
                                className="btn  btn-sm btn-secondary full-width"
                              >
                                {t("common.clear")}
                              </button>
                            </Grid>
                            <Grid item xs={8} sm={7}>
                              <button
                                type="submit"
                                className="btn  btn-sm btn-primary full-width"
                              >
                                {t("changePassword.header")}
                              </button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                      </div>
                    </div>
                    
                  </div>
                </Container>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  loggedInUser: state.login.data,
  stateWindow: state.window
});
const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile()),
  changePassword: user => dispatch(changePassword(user)),
  logoutAction: user => dispatch(logoutAction()),
  showAlert: (msg, style, heading) => dispatch(showAlert(msg, style, heading)),
  hideAlert: () => dispatch(hideAlert())
});
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withStyles(styles)
)(ChangePassword);
