import React, { Component } from "react";
import { connect } from "react-redux";


class ACL extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  check = () => {
    if (!this.props.functionId || !this.props.userPermissions) {
      return false;
    }
   return this.props.userPermissions[this.props.functionId] === 'W';
  };

  render() {
    return (
      <React.Fragment>
        {this.check() ? this.props.children :  null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userPermissions: state.login.aclData.acl
});

export default  connect(mapStateToProps)(ACL);
