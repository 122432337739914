import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: 'None',
    textAlign: 'left',
    color: theme.palette.text.secondary,

  },
  card: {
    maxWidth: 345,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    marginLeft: 'auto',
  },
}))

const ErrorPage = (props) => {
  console.log("ErrorPage props" , props);
  const classes = useStyles()
  const divStyle = {
    marginTop: "80px"
  }

  return (
    <Container maxWidth="md" style={divStyle}>
      <div className="flex-center">

        <section id="mainSection">
          <div className="container">
            <div className="row">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper} >
                    <div className="alert alert-danger cb-alert-heading" role="alert" >
                      { props && props.isValidURL !== null && props.isValidURL === false ? 
                      (
                      <span><h2>Unauthorized Access</h2><p>Operation you are performing is unauthorized or the link you are using is expired</p> </span>) 
                      : <span><h2>Page Not Found</h2><p>Sorry, page you are looking for is not found </p> </span>
                      }
                      
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>

      </div>
    </Container>
  )
}
export default ErrorPage