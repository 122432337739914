import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InfoRounded from "@material-ui/icons/InfoRounded";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { submitForgot } from "../../redux/auth/action";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import { showAlert, hideAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";
import Progress from "../../Progress"
import ReactGA from 'react-ga';
const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  leftpaper: {
    marginTop: theme.spacing(0),
    textAlign: "left",
    padding: "10px",
    height: "100%"
  },

  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(0)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#fedb00",
    color: "#1e1e1e"
  },
  container: {
    marginBottom: "20px"
  },
  card: {
    maxWidth: 345
  },
  infoAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.isRegistered = false;
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.login.isloggedIn) {
      this.props.history.push("/");
      return;
    }
    this.props.hideAlert();
    ValidatorForm.addValidationRule("isRegistered", value => {
      if (this.isRegistered) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isRegistered");
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.isRegistered = "";
  }

  handleSubmit = e => {
    e.preventDefault();
    //    this.props.hideAlert()
    this.setState({ submitted: true });
    const { email } = this.state;
    console.log("Email" + email);

    if (email) {
    	this.props.showProgress();
      this.props.sumbitForgotPassword(this.state).then(response => {
        this.setState({ submitted: false });
        if (response.result === "SUCCESS" || (response.username && response.username === "Invalid User or doesn't exists" )) {
        	ReactGA.pageview("/forgotpassword/success");
        	ReactGA.event({
  			  category: "Login",
  			  action: "Forgot Password Success",
  			});
  			window.dataLayer.push({'event': "custom_event",
                               'eventAction': "Forgot Password Success",
                               'eventCategory': "Login",
                               'eventLabel': "Login"});
          this.props.showAlert(
            "If the email address submitted is associated with a valid account, a password reset link has been sent. If you do not receive this email, please check your spam folder or sign-up for an account.",
            "success"
          );
          this.setState({ email: "" });
          //          this.props.history.push("/login");
        } else {
          if (response.result && response.result === "FAILED") {
        	  ReactGA.pageview("/forgotpassword/failed");
        	  ReactGA.event({
    			  category: "Login",
    			  action: "Forgot Password Failed",
    			});
    			window.dataLayer.push({'event': "custom_event",
                                 'eventAction': "Forgot Password Failed",
                                 'eventCategory': "Login",
                                 'eventLabel': "Login"});
            this.props.showAlert(
              "Invalid email, please check the email address that you entered.",
              "error"
            );
            this.isRegistered = true;
            this.formRef.current.childs[0].validate();
          }
        }
        this.props.hideProgress();
      });
    }
  };

  render() {
    const { classes } = this.props;
    const divStyle = {
      marginTop: "2em",
      marginBottom: "4em"
    };
    const divStyleAlert = {
      marginTop: "4em",
      marginBottom: "4em"
    }
    const { t } = this.props;
    return (
      <Container maxWidth="md" style={this.props.alert.showAlert ? divStyleAlert : divStyle}>
        <div>
          <div className="row">
            <Paper className={`${classes.paper} paper-box-container`}>
            <div className="row col-xs-12 col-sm-12" style={{height:'10px'}}>
            <Progress />
            </div>
              <div className="hDivider"></div>

              <Grid container>
                <Grid item xs={12}>
                  <Container component="main" maxWidth="lg">
                    <div className={classes.paper}>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Paper className={classes.leftpaper}>
                            <Avatar
                              className={`${classes.infoAvatar} margin-center`}
                            >
                              <InfoRounded />
                            </Avatar>
                            <Typography
                              variant="h5"
                              component="h2"
                              color="primary"
                              style={{ textAlign: "center" }}
                            >
                              {t("forgotPassword.information")}
                            </Typography>
                            <br />
                            <Typography component="p">
                              {t("forgotPassword.pleaseCheckSpam")}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Container maxWidth="lg">
                            <div className={classes.paper}>
                              <Avatar
                                className={`${classes.avatar} margin-center`}
                              >
                                <LockOutlinedIcon />
                              </Avatar>
                              <Typography
                                component="h2"
                                variant="h5"
                                align="center"
                              >
                                Forgot Password
                              </Typography>
                              <br />
                              <ValidatorForm
                                className={classes.form}
                                ref={this.formRef}
                                noValidate
                                onSubmit={this.handleSubmit}
                                onError={errors => console.log(errors)}
                                autocomplete="off"
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      id="email"
                                      label={t("forgotPassword.email")}
                                      name="email"
                                      type="email"
                                      required
                                      autoComplete="email"
                                      validators={["required", "isEmail"]}
                                      errorMessages={[
                                        "Email Address is required.",
                                        "Invalid Email Address."
                                      ]}
                                      value={this.state.email}
                                      inputProps={{ maxLength: 250 }}
                                      onChange={this.handleChange}
                                    />
                                  </Grid>
                                </Grid>

                                <button
                                  type="submit"
                                  disabled={this.state.submitted}
                                  className="btn  btn-sm btn-primary full-width"
                                >
                                  Submit
                                </button>
                                <br />
                                <br />
                                <Grid container justify="flex-end">
                                  <Grid item>
                                    <Link to="/" variant="body2">
                                      Back to Sign In
                                    </Link>
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            </div>
                          </Container>
                        </Grid>
                      </Grid>
                    </div>
                  </Container>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </Container>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  sumbitForgotPassword: user => dispatch(submitForgot(user)),
  showAlert: (msg, style, heading) => dispatch(showAlert(msg, style, heading)),
  hideAlert: () => dispatch(hideAlert()),
  showProgress: () => dispatch(showProgress()),
  hideProgress: () => dispatch(hideProgress())
});
const mapStateToProps = state => ({
  ...state
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withTranslation()
)(ForgotPassword);
