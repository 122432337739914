import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import Routes from "./Routes";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Titlebar from "./components/TitleBar";
import { logoutAction, getGoogleAppId } from "./redux/auth/action";
import "./App.css";
import Alerts from "./Alerts"
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Link from '@material-ui/core/Link';
import ModalRoot from './ModalRoot'

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      hide: true,
      showAlert: true,
	  style: "",
	  heading: "",
	  msg: ""
    };
  }
  toggleHeader = () => {
    const { hide } = this.state;
    this.setState({ hide: !hide });
  };
  async componentDidMount() {
	  this.props.getGoogleAppId().then(response => {
		  // console.log(response)
		  ReactGA.initialize(response);
		  ReactGA.pageview(window.location.pathname);
		  ReactGA.event({
			  category: "App Start",
			  action: "App Loaded",
			});
			window.dataLayer.push(
                        {'event': "custom_event",
                        'eventAction': "App Loaded",
                    		'eventCategory': "App Start",
                    		'eventLabel': "Application"});
      })
  }
  componentDidUpdate() {
  	// console.log(window.location.pathname)
      ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (

      <Router history={history}>
		  <div>
            <Link color="secondary" className="skipNav" href={(this.props.login && !this.props.login.isloggedIn) ? '#main-content' : '#loggedin-main-content'}
              aria-label="skip to content">
              Skip to content
            </Link>
             <Header state={this.props} onLogout={this.props.logoutAction} />
          {this.props.login
            && !this.props.login.isloggedIn
            && <div><Titlebar />
				<div className="col-sm-12" style={{height:'100px'}}>
		      <Alerts />
		      </div>
	      </div>}
		  <div className="col-sm-12" id="main-content">
          <Routes state={this.props} />
		  </div>
          <Footer />
		  </div>
        <ModalRoot hideModal={this.props.hideModal} />
      </Router>

    );
  }
}
const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  logoutAction: user => dispatch(logoutAction()),
  getGoogleAppId: () => dispatch(getGoogleAppId())
});
export default connect(
  mapStateToProps, mapDispatchToProps
)((App));
