import C from "../../redux/constants"

export default (state = { isloggedIn: false }, action) => {
    switch (action.type) {
        case C.LOGIN_SUCCESSFULL:
            return {
                ...state,
                isloggedIn: true,
                data: action.data
            };
        case C.FORGOT_SUCCESSFUL:
            return {
                ...state,
                data: action.data
            };

        case C.ACL_SUCCESSFUL:
            state = {
                ...state,
                aclData: action.data
            };
            sessionStorage.setItem('userSession', JSON.stringify({ login: state }));
            return state;
        case C.LOGOUT_SUCCESSFUL:
            return {
                ...state,
                isloggedIn: false,
                logoutData: action.data
            };
        case C.UPDATE_USER_NAME:
            let { data } = state;
            data={...data,...action.data}
            state = {
                ...state,
                data
            };
            sessionStorage.setItem('userSession', JSON.stringify({ login: state }));
            return state;
        default:
            return {
                ...state
            };
    }
};
