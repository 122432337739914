import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { withRouter } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    marginLeft: 'auto',
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    borderBottom: '2px solid #ccc'
  },
  appTool: {
    borderTop: '4px solid #702f8a',
    minHeight: "48px"
  },
  avatar: {
    padding: 5,
  },
  accountCircleFont: {
    width: '1.2em',
    height: '1.2em'
  },
  button:{
	  backgroundColor: "#fedb00",
	    color: "#1e1e1e",
  }
}))

const Header = (props) => {

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [isModalOpen, setOpen] = React.useState(false);
  const [isTdModalOpen, setTdOpen] = React.useState(false);
  const {t} = useTranslation();
  const divStyle = {
    // marginTop: '-12px',
    borderBottom: 'none',
    flexGrow: 1,
    minWidth: "246px"
  }
  const aStyle = {
    lineHeight: '44px'
  }
  const toggleModal = (isOpen) => {
    
    if (props.state.window.windowObj && props.state.window.windowObj.w && !props.state.window.windowObj.w.closed) {
      setTdOpen(true);
    }
    setOpen(isOpen);

    if (isOpen) 
    {
      setAnchorEl(null)
    }else{
      if (props.state.window.windowObj && props.state.window.windowObj.w) {
        setTdOpen(false);
        props.state.window.windowObj.w.focus();
      }
    }
   

  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose(event) {
    event.preventDefault();
    setAnchorEl(null);
  }

  function showProfile(event) {
    event.preventDefault();
    console.log("Header props history", props);
    props.history.push("/home/profile");
    setAnchorEl(null);
  }

  function handleClose(event) {
    event.preventDefault();
    console.log("Header propshistory", props);
    if (props.state.window.windowObj && props.state.window.windowObj.w) {
      setTdOpen(false);
      props.state.window.windowObj.w.close();

    }
    toggleModal(false)
    props.onLogout().then((res) => {
      console.log(res)
      setAnchorEl(null);
      props.history.push("/");
    })
  }
  return (
    <AppBar className={classes.appBar} role="banner">
      <Toolbar className={`${classes.appTool} row`}>
        <div className="lv-globalHeader-widget" style={divStyle}>
          <div className="lv-gh-type-higher-ed">
            <div className="lv-gh-title">
              <div className="lv-gh-menu lv-gh-item ">
                <a href="//www.collegeboard.org/?navId=accu-cb"
                  aria-label="The College Board" target="_blank"
                  rel="noopener noreferrer" title="[Opens in New Window] ">
                  <span aria-hidden="true" className="cb-glyph cb-acorn"></span>
                </a>
              </div>
              <div className="lv-gh-item lv-gh-lockup lv-gh-lockup-id-accuplacer">
                <a style={aStyle} className="lv-lockup-logo cb-programs-accuplacer-e lv-logo-id-accuplacer" href="/?navId=accu-lu" aria-label="Accuplacer">
                  <span className="lv-lockup-name">Accuplacer</span>
                </a>
              </div>
            </div>


          </div>
        </div>
        {props.state.login.isloggedIn ? (
          <div className="col-xs-5">

            <IconButton className={`${classes.avatar} pull-right`}
              aria-label="Account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle className={classes.accountCircleFont} />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={showProfile}>Profile</MenuItem>
              <MenuItem onClick={() => toggleModal(true)}>Logout</MenuItem>
            </Menu>
            <span className={`${classes.avatar} pull-right  hidden-xs header-user-name`}>
              {props.state.login.data.firstName} {props.state.login.data.lastName}
            </span>
            {/* </Grid> */}

          </div>)
          : null}
      </Toolbar>

      <Dialog
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{isTdModalOpen? ("A test is already in-progress!"):("Logout?")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"> 
            {isTdModalOpen? (t('common.tdInprogressMsg')):(t('common.logoutMsg'))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={() => toggleModal(false)} color="primary" >
            No
          </Button>
        <Button onClick={handleClose} autoFocus className={classes.button}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  )
}



export default (withRouter(Header));