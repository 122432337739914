import axiosInstance from "../services/API";


export function getHistory(userObj) {
    return function (dispatch) {
      return axiosInstance
        .post("/api/getHistory", userObj)
        .then(response => {
          return response.data;
        })
        .catch(response => {
          console.log(response);
        });
    };
  }

export function printHistory() {
    return function (dispatch) {
      return axiosInstance
        .post("/api/printHistory")
        .then(response => {
          return response.data;
        })
        .catch(response => {
          console.log(response);
        });
    };
  }