import React, { Component } from 'react'
import TopNav from './TopNav'
import Progress from "../../Progress"

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  marginStyle: {
    marginTop: '70px'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrumps: {
    marginTop: '30px',
  }
})

//function handleClick(event) {
//  event.preventDefault()
//}
class NavBar extends Component {
	
	  render() {
		    const { classes } = this.props;
 
		    return (
    <div className={classes.marginStyle}>
      <div className="cb-local-navigation jquery-once-1-processed" style={{borderBottom:'1px solid #d9d9d9'}} data-cbtrack-navview="">
        <nav role="navigation" aria-label="local navigation menu" className="cb-desktop-navigation">
          <div className="row">
            <div className="col-sm-5 hidden-xs">
              <h1 className="cb-nav-title" style={{whiteSpace:'nowrap',padding:'0px'}}>Practice App</h1>
            </div>  
            <div className="col-xs-12 col-sm-7" style={{height:'auto'}}>
                <TopNav />
            </div>
          </div>
        </nav>
        <div className="col-xs-12 col-sm-12" style={{height:'10px'}}>
        <Progress />
        </div>
      </div>
    </div>
  )
	  }
}
NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(NavBar)