import React, { Component } from 'react'
import { Link } from "react-router-dom"
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import queryString from 'query-string'
import { connect } from "react-redux";
import { compose } from "redux";
import { submitResetPassword, enterResetPassword } from "../../redux/auth/action";
import { withTranslation } from "react-i18next";
import { showAlert, hideAlert } from '../../redux/alertActions';
import { PasswordValidationRegex } from '../../shared/Validation';
import { showProgress, hideProgress } from "../../redux/progressActions";
import Progress from "../../Progress"
import ReactGA from 'react-ga';
import ErrorPage from '.././ErrorPage';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
     padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  leftpaper: {
    marginTop: theme.spacing(0),
    textAlign: 'left',
    padding: '10px',
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    backgroundColor: '#fedb00',
    color: '#1e1e1e'
  },
  container: {
    marginBottom: '20px',
  },
  card: {
    maxWidth: 345,
  },
})

class ResetPassword extends Component {

  constructor(props) {
    super(props)
    const values = queryString.parse(this.props.location.search)
    // reset login status
    this.state = {
      email: '',
      password: '',
      confirmpassword: '',
      userid: '',
      submitted: false,
      data: values,
      isValidURL:true
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.login.isloggedIn) {
      this.props.history.push("/");
      return;
    }
    this.props.hideAlert()
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value && (value!== "") && (value !== this.state.password)) {
        return false;
      }
      return true;
    });

    // custom rule for password validation
    ValidatorForm.addValidationRule('matchPasswordCriteria', PasswordValidationRegex);

    const { data } = this.state;
    if (data) {
      this.props.submitResetPassword(this.state).then(response => {
        if (response && response.result && response.result === "SUCCESS") {
        	ReactGA.pageview("/resetpassword/success");
          this.setState({ email: response.email })
          this.setState({ userid: response.userId })
          if(response && response.isValidURL === true){
            this.setState({ isValidURL: response.isValidURL })
          }

        }else if(response && response.result && response.result === "FAILED" &&
        response && response.isValidURL !== null && response.isValidURL === false ){
        	ReactGA.pageview("/resetpassword/failed");
          this.setState({ isValidURL: false })
        }
      });
    }
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.hideAlert()
    this.setState({ submitted: true })
    const { email, password, confirmpassword, userid } = this.state

    if (email && password && confirmpassword && userid) {
    	this.props.showProgress();
      this.props.enterResetPassword(this.state).then(response => {
        if (response.result === "SUCCESS") {
        	ReactGA.event({
  			  category: "Login",
  			  action: "Reset Password Success",
  			});
  			window.dataLayer.push(
                          {'event': "custom_event",
                          'eventAction': "Reset Password Success",
                      		'eventCategory': "Login",
                      		'eventLabel': "Login"});
          this.onResetClick();
          this.props.showAlert("Your password has been updated. Please use the new password during your next login", "success")
          // this.props.history.push('/login')
        }  else {
        	ReactGA.event({
			  category: "Login",
			  action: "Reset Password Failed",
			});
			window.dataLayer.push(
                        {'event': "custom_event",
                        'eventAction': "Reset Password Failed",
                    		'eventCategory': "Login",
                    		'eventLabel': "Login"});
        }
        if(response.result === "FAILED" && response.isVoilations === "You are unauthorized"){
           this.props.showAlert("You are unauthorized", "error");
        }
        this.props.hideProgress();
      });
    }
  }
  onResetClick = () => {
    this.setState({
      password: '',
      confirmpassword: '',
    });
  }

  render() {

    const { classes } = this.props
    const divStyle = {
      marginTop: "2em",
      marginBottom: "4em"
    }
    const { t } = this.props;
    return (
      <div>
        { this.state.isValidURL ? (
        <Container maxWidth="md" style={divStyle}>
          <div className="row">
            <Paper className={`${classes.paper} paper-box-container`} >
            <div className="row col-xs-12 col-sm-12" style={{height:'10px'}}>
            <Progress />
            </div>
              <div className="hDivider" ></div>
              <Grid container spacing={3}>
                <Grid item xs={12}>

                  <Container component="main" maxWidth="lg">
                    <div className={classes.paper}>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Paper className={classes.leftpaper}>

                            <Typography variant="h5" component="h3" style={{ textAlign: 'center' }} color="primary">
                              {t('resetPassword.information')}
                            </Typography>
                            <Typography component="p">
                              {t('resetPassword.passwordLength')}
                            </Typography>
                            <Typography component="div" color="secondary" >
                              <ul className="passCriteriaUl">
                                <li>{t('resetPassword.lowercase')}</li>
                                <li>{t('resetPassword.numeral')}</li>
                                <li>{t('resetPassword.uppercase')}</li>
                                <li>{t('resetPassword.specialCharacter')}</li>
                              </ul>
                            </Typography>

                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.paper}>
                            <Avatar  className={`${classes.avatar} margin-center`}>
                              <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5"  style={{textAlign: 'center',marginTop:10}}>
                              Reset Password
                            </Typography>
                            <ValidatorForm
                              className={classes.form}
                              noValidate
                              ref="form"
                              onSubmit={this.handleSubmit}
                              onError={errors => console.log(errors)}
                              autocomplete="off"
                            >
                              <Grid container spacing={2}>

                                <Grid item xs={12}>
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    disabled
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    value={this.state.email}
                                    autoComplete="email"
                                    onChange={e => {
                                      this.setState({ email: e.target.value })
                                      this.value = this.state.email;
                                    }}
                                    autoFocus
                                    validators={['required', 'isEmail']}
                                    errormessages={['this field is required', 'email is not valid']}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    validators={['required', 'matchPasswordCriteria']}
                                    errorMessages={['Password is required.', 'Password must match criteria.']}
                                    value={this.state.password}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={this.handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmpassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmpassword"
                                    autoComplete="new-password"
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['Passwords typed do not match. Please re-enter your password.', 'Confirm Password is required.']}
                                    value={this.state.confirmpassword}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={this.handleChange}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} className="change-password-btn">

                                <Grid item xs={4}  sm={5}>
                                <button
                                type="button"
                                onClick={this.onResetClick}
                                className="btn  btn-sm btn-secondary full-width"
                              >
                               Clear
                              </button>
                                </Grid>
                                <Grid item xs={8}  sm={7}>
                                  <button type="submit" className="btn  btn-sm btn-primary full-width">Reset Password</button>
                                </Grid>
                              </Grid>
                              <br />

                              <Grid container justify="flex-end">
                                <Grid item>
                                  <Link to="/" variant="body2">
                                    Sign In
                                        </Link>
                                </Grid>
                              </Grid>
                            </ValidatorForm>
                          </div>
                        </Grid>
                      </Grid>


                    </div>
                  </Container>

                </Grid>
              </Grid>
            </Paper>
          </div>
        </Container>) :(<div>
          <ErrorPage isValidURL = {this.state.isValidURL}/>
        </div>)}

      </div>
    )
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  submitResetPassword: user => dispatch(submitResetPassword(user)),
  enterResetPassword: user => dispatch(enterResetPassword(user)),
  showAlert: (msg, style, heading) => dispatch(showAlert(msg, style, heading)),
  hideAlert: () => dispatch(hideAlert()),
  showProgress: () => dispatch(showProgress()),
  hideProgress: () => dispatch(hideProgress())
});
const mapStateToProps = state => ({
  ...state
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ResetPassword);
