import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: "#fedb00",
        color: "#1e1e1e",
    }
}));
const AlertModal = ({
  closeModal,
  title,
  message
}) => {
	
	const classes = useStyles();
  
  return (
    <React.Fragment>
      <DialogTitle className="dialog-title" id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} className={classes.button} >
          Ok
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
export default AlertModal;
