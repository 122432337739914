import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { showAlert, hideAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";
import ReactGA from "react-ga";
import { showModal, hideModal } from "../../redux/modalAction";
import { storeWindow } from "../../redux/windowActions";

import { getTests } from "../../redux/userActions";
import { startTest } from "../../redux/auth/action";

import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "None",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ulStyle: {
    justifyContent: "center",
    marginTop: "10px !important",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const PAAStep3 = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [horizontal] = React.useState("left");
  const [vertical] = React.useState("top");
  const [step3Steps, setStep3Steps] = useState();
  const step3Data =
    props.paa && props.paa.step3Data ? props.paa.step3Data : step3Steps;
  const [userId, setUserId] = useState(0);
  const [noitem, setNoitem] = useState("");

  useEffect(() => {
    dispatch(hideAlert());
    let testCategoryData = {
      categoryId: 5,
      paaId: props.paa.paaId,
    };
    dispatch(showProgress());
    dispatch(getTests(testCategoryData)).then((res) => {
      setStep3Steps(res.testData);
      if (res.testData.length === 0) {
        setNoitem("No test available");
      }
      setUserId(res.userId);
      dispatch(hideProgress());
    });
  }, []);

  //copy-pasted from test.js

  const dialogDesc = (
    <React.Fragment>
      Are you sure you want to continue? If yes, the in-progress test session
      will be closed and a new test session will begin.
    </React.Fragment>
  );
  const dialogTitle = (
    <React.Fragment>A test is already in-progress!</React.Fragment>
  );

  const dialogOnSubmit = (userData, dataUserId, isSampleTest, ptTestMode) => {
    // close the existing window and starting the new test.
    if (props.windowObject && props.windowObject.w) {
      props.windowObject.w.close();
    }
    handleTestOnClick(userData, dataUserId, isSampleTest, ptTestMode);
    dispatch(hideModal());
  };

  const dialogOnClose = (event, reason) => {
    // cancel the request and put focus back to the already in-progress test.
    if (props.windowObject && props.windowObject.w) {
      props.windowObject.w.focus();
    }
    if ("backdropClick" !== reason) dispatch(hideModal());
  };

  const dialogAttr = {
    disableEscapeKeyDown: true,
  };

  const openTestIncompleteAlertModal = (step3Data) => {
    let alertTitle = "Tests Incomplete!";
    let alertMessage = "Please complete both sample tests to proceed.";
    dispatch(
      showModal(
        {
          title: alertTitle,
          message: alertMessage,
          closeModal: dialogOnClose,
          dialogAttr: dialogAttr,
        },
        "alert"
      )
    );
  };
  //   if (props.paa && props.paa.step3DataInvalid) {
  //     openTestIncompleteAlertModal(props.paa.step3Data);
  //   }

  const openConfirmModal = (...callBackParams) => {
    dispatch(
      showModal(
        {
          title: dialogTitle,
          message: dialogDesc,
          confirmAction: dialogOnSubmit,
          confirmActionParams: callBackParams,
          closeModal: dialogOnClose,
          dialogAttr: dialogAttr,
        },
        "confirm"
      )
    );
  };

  const confirmStartTest = (userData, dataUserId, isSampleTest, ptTestMode) => {
    if (
      props.windowObject &&
      props.windowObject.w &&
      !props.windowObject.w.closed
    ) {
      openConfirmModal(userData, dataUserId, isSampleTest, ptTestMode);
    } else {
      handleTestOnClick(userData, dataUserId, isSampleTest, ptTestMode);
    }
  };

  const populateWindowObject = (userData, dataUserId, w) => {
    // console.log(props.windowObject);
    const newWindowObject = {};
    newWindowObject.testAlias = userData.testAlias;
    newWindowObject.userId = dataUserId;
    newWindowObject.w = w;
    dispatch(storeWindow(newWindowObject));
  };

  const handleTestOnClick = (
    userData,
    dataUserId,
    isSampleTest,
    ptTestMode
  ) => {
    let tdDomainUrl = props.urlData.tddomainUrl;
    let path;
    let pendingSessionId;
    let testCategoryData = {
      categoryId: 5,
      paaId: props.paa.paaId,
    };

    dispatch(getTests(testCategoryData)).then((res) => {

      userData = res.testData.filter((data) => data.bpId === userData.bpId)[0]
      setStep3Steps(res.testData);
      if (res.testData.length === 0) {
        setNoitem("No test available");
      }
      setUserId(res.userId);
      dispatch(hideProgress());

      if (userData) {
        if (isSampleTest && userData.pendingSAMPSessionId) {
          pendingSessionId = userData.pendingSAMPSessionId;
          path = "resumePracticeTestSession";
        } else if (!isSampleTest && userData.pendingLAYGSessionId) {
          pendingSessionId = userData.pendingLAYGSessionId;
          path = "resumePracticeTestSession";
        } else {
          path = "welcome";
        }
      } else {
        path = "welcome";
      }
      ReactGA.event({
        category: userData.branchingProfileName,
        action: "PAA Test Administration",
        value: {
          userId: dataUserId,
          testAlias: userData.testAlias,
          bpId: userData.branchingProfileId,
          bpName: userData.branchingProfileName,
          path: path,
        },
      });

      var jsonData = {
        testAlias: userData.parentTestAlias,
        categoryId: userData.categoryId,
        administerTest: true,
        isDemoSite: false,
        isKioskApp: false,
        kioskData: {},
        path: path,
        ethnicity: "N/A",
        gender: "N/A",
        firstName: userData.firstName,
        lastName: userData.lastName,
        isPracticeTest: true,
        isSamplePtTest: isSampleTest,
        ptTestMode: ptTestMode,
        pendingSessionId: pendingSessionId,
        isPAASession: true,
        paaId: props.paa.paaId,
      };

      dispatch(startTest(jsonData)).then((res) => {
        // console.log(res);
        ReactGA.set({
          page: window.location.pathname + "/" + userData.branchingProfileName,
        });
        ReactGA.pageview(
          window.location.pathname + "/" + userData.branchingProfileName
        );
        window.dataLayer.push(
                          {'event': "custom_event",
                          'eventAction': window.location.pathname + "/" + userData.testName,
                      		'eventCategory': "PreAssessmentStep3Content",
                      		'eventLabel': "TSIA2 PAA"});
        let tdURL;
        if (tdDomainUrl && tdDomainUrl !== null && tdDomainUrl !== "") {
          tdURL = tdDomainUrl + "#/?d=" + res;
        } else {
          tdURL = "http://test.local-accuplacer.com:9004/#/?d=" + res;
        }

        let screen = window.screen;
        let wwidth = screen.width,
          wheight = screen.height,
          LeftPosition = 0,
          TopPosition = 0;
        var varWin = window.open(
          tdURL,
          "atWindow",
          "menubar=no,height=" +
          wheight +
          ", width=" +
          wwidth +
          ",top=" +
          TopPosition +
          ",left=" +
          LeftPosition +
          ",scrollbars=yes, resizable=yes, toolbar=no, menubar=no, status=no,location=no"
        );
        if (varWin) {
          varWin.moveTo(0, 0);
          varWin.resizeTo(screen.width, screen.height);
          varWin.focus();
          populateWindowObject(userData, dataUserId, varWin);
        }
      });
    });
  };
  //copy-pasted from test.js

  return (
    <React.Fragment>
      {step3Data ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={8}>
            <Alert severity="info">
            Please complete both sample tests to proceed.
            </Alert>
          </Grid>

          <Grid item xs={12} sm={8} className="cb-item-list cb-item-list-rich">
            <ul className={classes.ulStyle}>
              {step3Data.length === 0 && (
                <Typography variant="h5" component="h5">
                  {noitem}
                </Typography>
              )}
              {step3Data.length > 0 &&
                step3Data.map((row, index) => (
                  <li className="cb-type-article noTitleBar" key={index}>
                    <div className="cb-item-icon">
                      <span
                        className="cb-glyph cb-glyph-circular cb-glyph-higher-education cb-compose"
                        aria-hidden="true"
                      ></span>
                      <span className="cb-item-type">{row.testName}</span>
                    </div>
                    <div className="cb-item-text">
                      <h4 className="cb-item-title">{row.testName}</h4>
                      <p className="cb-item-des">{row.testDescription}</p>
                    </div>
                    <div className="cb-item-meta">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {row.pendingLAYGSessionId != null &&
                          row.pendingLAYGSessionId > 0 &&
                          (!row.completedLAYGSessionId ||
                            row.completedLAYGSessionId == 0) && (
                            <Badge
                              color="secondary"
                              badgeContent={"In Progress"}
                              anchorOrigin={{
                                horizontal,
                                vertical,
                              }}
                              overlap="circular"
                              className={classes.margin}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={(event) =>
                                  confirmStartTest(row, userId, false, 1)
                                }
                                data-testalias={row.testAlias}
                                aria-label={` ${row.testName} completed`}
                              >
                                Begin Sample Test
                              </Button>
                            </Badge>
                          )}
                        {!!(
                          row.completedLAYGSessionId &&
                          row.completedLAYGSessionId > 0
                        ) && (
                          <Badge
                            color="secondary"
                            badgeContent={"Completed"}
                            anchorOrigin={{
                              horizontal,
                              vertical,
                            }}
                            overlap="circular"
                            className={classes.margin}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              onClick={(event) =>
                                confirmStartTest(row, userId, false, 1)
                              }
                              data-testalias={row.testAlias}
                              aria-label={` ${row.testName} test in progress`}
                            >
                              Begin Sample Test
                            </Button>
                          </Badge>
                        )}
                        {(!row.completedLAYGSessionId ||
                          row.completedLAYGSessionId == 0) &&
                          (row.pendingLAYGSessionId == null ||
                            row.pendingLAYGSessionId == 0) && (
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              onClick={(event) =>
                                confirmStartTest(row, userId, false, 1)
                              }
                              data-testalias={row.testAlias}
                              aria-label={` ${row.testName} test`}
                            >
                              Begin Sample Test
                            </Button>
                          )}
                      </Grid>
                    </div>
                  </li>
                ))}
            </ul>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  paa: state.paa,
  urlData: state.login.data,
  windowObject: state.window.windowObj,
});
export default connect(mapStateToProps)(PAAStep3);
