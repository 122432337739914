import C from "./constants"
export const showAlert = (msg, style, heading) => {
  return {
    type: C.ADD_ALERT,
    style,
    heading,
	msg
  };
};

export const hideAlert = (id) => {
  return {
    type: C.REMOVE_ALERT,
    id
  };
};