import axiosInstance from "../services/API";


export function getProfile() {
  return function (dispatch) {
    return axiosInstance
      .post("/api/getProfile")
      .then(response => {
        return response;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function saveProfile(userObj) {
  return function (dispatch) {
    return axiosInstance
      .post("/api/saveProfile", userObj)
      .then(response => {
        return response;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function changePassword(userObj) {
  return function (dispatch) {
    return axiosInstance
      .post("/api/changePassword", userObj)
      .then(response => {
        return response;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function getUsers(searchObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/getUsers", searchObj)
      .then(resp => {
        return resp.data;
      })
  };
}

export function exportUsers(searchObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/exportUsers", searchObj)
      .then(resp => {
        return resp.data;
      })
  };
}

export function deleteUser(searchObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/deleteUser", searchObj)
      .then(resp => {
        return resp.data;
      })
  };
}

export function getTests(searchObj) {
  return function (dispatch) {
    return axiosInstance.post("/api/getAllTests", searchObj)
      .then(resp => {
        return resp.data;
      })
  };
} 