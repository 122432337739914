import React, { useRef, useLayoutEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, FormControl} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import { inviteUsersSendMail } from "../../redux/auth/action";
import Input from "@material-ui/core/Input";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useTranslation } from "react-i18next";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { showAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";

export default function InviteUser(props) {
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [alreadyExists, setAlreadyExists] = React.useState(false);
  const [values, setValues] = React.useState({
    userRoleId: "",
    userEmail: "",
    name: ""
  });
  const formRef = useRef();
  const { t } = useTranslation();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    reset();
  }

  const handleInputChange = event => {
    event.persist();
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
  };

  const dispatch = useDispatch();
  const handleSubmit = () => {
	  
    if (
      values &&
      //values.userRoleId !== null && 
      values.userRoleId !== 1 &&
      values.userEmail !== ""
    ) {
    	dispatch(showProgress())
      dispatch(inviteUsersSendMail(values)).then(res => {
        if (res.userAlreadyRegistered && res.userAlreadyRegistered === 'Y') {
          setAlreadyExists(true);
          formRef.current.childs[1].validate();
        }
        else {
          handleClose();
          dispatch(showAlert("The user invitation has been sent successfully", "success"))
        }
        dispatch(hideProgress())
      });
    } else {
      setDisabled(true);
      return false;
    }
  };

  const reset = event => {
    setValues({
      userRoleId: "",
      userEmail: "",
      name: ""
    });
    setDisabled(true);
  };
  useLayoutEffect(() => {
  // custom rule for user role validation
  ValidatorForm.addValidationRule('requiredRole', value => {
    if (values && (value === 1)) {
      return false;
    }
    return true;
  });

  // custom rule for already existing email validation
  ValidatorForm.addValidationRule('alreadyRegistered', () => {
    if (alreadyExists) {
      setAlreadyExists(false);
      return false;
    }
    return true;
  });

  if ((values.userRoleId === "" || values.userRoleId === 1) ||  values.userEmail === "") {
    setDisabled(true);
  } else {
    setDisabled(false);
  }

  return () => {
    ValidatorForm.removeValidationRule("requiredRole");
    ValidatorForm.removeValidationRule("alreadyRegistered");
  };
  })


  return (
    <React.Fragment>

      <button type="button" className={` btn btn-sm btn-primary`}
        style={{ display: 'flex' }}
        onClick={handleClickOpen} >
        <PersonAddIcon />&nbsp;
        Invite New User
        </button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className="dialog-title" id="form-dialog-title">
          <span>
            <PersonAddIcon /> {t("inviteUser.dialogTitle")}
          </span>
          <span style={{ float: 'right' }}>
            <HighlightOffRoundedIcon onClick={handleClose} style={{ color: 'black',cursor:'pointer' }} />
          </span>
        </DialogTitle>
        <DialogContent>
          <br />

          <ValidatorForm ref={formRef} onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}  
             style={{height:"100px"}}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  style={{ width: "80%" }}
                >
                  <SelectValidator
                    variant="outlined"
                    value={values.userRoleId}
                    onChange={handleInputChange}
                    input={
                      <Input name="userRoleId" id="age-label-placeholder" />
                    }
                    name="userRoleId"
                    required
                    label={t("inviteUser.userRoleLabel")}
                    validators={["requiredRole"]}
                    errorMessages={["User Role is required"]}
                  >
                    <MenuItem value={3}>{t("inviteUser.userRoleMenuItems.contentAdmin")}</MenuItem>
                    <MenuItem value={4}>{t("inviteUser.userRoleMenuItems.reviewer")}</MenuItem>
                    <MenuItem value={5}>{t("inviteUser.userRoleMenuItems.publisher")}</MenuItem>
                  </SelectValidator>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="userEmail"
                  autoComplete="email"
                  type="email"
                  required
                  validators={["required", "isEmail", "alreadyRegistered"]}
                  errorMessages={[
                    "Email Address is required",
                    "Invalid Email Address",
                    "This Email Address is already registered"
                  ]}
                  inputProps={{ maxLength: 250 }}
                  value={(values && values.userEmail) || ""}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <button type="button" onClick={handleClose} className="btn btn-sm btn-secondary">Cancel</button>
              <button type="submit" disabled={disabled} className="btn  btn-sm btn-primary"> Submit</button>
            </DialogActions>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
