import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    text: {
        paddingTop: '70px',
        textAlign: 'center'
    },
    grow: {
        flexGrow: 1,
    },
}))
const TitleBar = () => {
    const classes = useStyles()
    return (
        <div className="region region-branding" >
            <div id="block-cb-hero-welcome-mat" className="block block-cb-hero">
                <div className="content">
                    <div className="cb-welcome-mat cb-wm-standard cb-wm-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 flex-block">
                                    <div className={`col-xs-12 ${classes.text}`}>
                                        <h1>Practice for ACCUPLACER</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="block-cb-highlighted-transaction-htm-band" className="block block-cb-highlighted-transaction">
                <div className="content">
                    <div className="ds-1col node node-article view-mode-htm_band clearfix">
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TitleBar