import C from "./constants"
export const showProgress = () => {
  return {
    type: C.SHOW_PROGRESS
  };
};

export const hideProgress = () => {
  return {
    type: C.REMOVE_PROGRESS
  };
};