const constants = {
	LOGIN_SUCCESSFULL: "LOGIN_SUCCESSFULL",
	ACL_SUCCESSFUL: "ACL_SUCCESSFUL",
	FORGOT_SUCCESSFUL: "FORGOT_SUCCESSFUL",
	RESET_PASSWORD_SUCCESSFUL: "RESET_PASSWORD_SUCCESSFUL",
	RESET_PASSWORD_ENTER: "RESET_PASSWORD_ENTER",
	HOME_CONTENT: "HOME_CONTENT",
	ADD_ALERT: "ADD_ALERT",
	REMOVE_ALERT: "REMOVE_ALERT",
	LOGOUT_SUCCESSFUL: "LOGOUT_SUCCESSFUL",
	UPDATE_USER_NAME:"UPDATE_USER_NAME",
	SHOW_PROGRESS:"SHOW_PROGRESS",
	REMOVE_PROGRESS:"REMOVE_PROGRESS"
}

export default constants
