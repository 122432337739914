import React, { Component } from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga'
import Tests from './home/Tests';
import PreAssessment from './preAssessmentActivity/PreAssessment';
import PreAssessmentWizard from './preAssessmentActivity/PreAssessmentWizard';
import PreAssessmentCertificate from './preAssessmentActivity/PreAssessmentCompletion';
import Test from './home/Test';
import TestHistory from './students/TestHistory';
import Users from './users/Users';
import Imports from './imports/Imports';
import UserProfile from './users/Profile';
import ViewPracticeTest from './imports/ViewPracticeTest';
import ChangePassword from './users/ChangePassword';
import ImportError from './imports/Error';
const history = createHistory()
history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

 export default class AppRoutes extends Component {

    componentDidMount() {
        // console.log(window.location.pathname)
        ReactGA.pageview(window.location.pathname)
    }

    componentDidUpdate() {
    	// console.log(window.location.pathname)
        ReactGA.pageview(window.location.pathname)
    }
    render() {
        return (
        		<Switch>
                <Route path='/home/index' exact component={Tests} />
                <Route path='/home/test/5' component={PreAssessment} />
                <Route path='/home/test/pre-assessment-wizard' component={PreAssessmentWizard} />
                <Route path='/home/test/pre-assessment-certificate' component={PreAssessmentCertificate} />
                <Route path='/home/test/:id' component={Test} />
                <Route path='/home/users' component={Users} />
                <Route path='/home/testhistory' component={TestHistory} />
                <Route exact path='/home/imports' component={Imports} /> 
                <Route exact path='/home/imports/practiceTest/:id' component={ViewPracticeTest} />
                <Route exact path='/home/imports/errors/:id' component={ImportError} />
                <Route path='/home/profile' component={UserProfile} />
                <Route path='/home/changepassword' component={ChangePassword} />
                <Route exact path="/home" render={() =>
                  <Redirect to="/home/index" />
                } />
              </Switch>
        )
    }
}
