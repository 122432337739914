import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#d5aee4',
  },
  barColorPrimary: {
    backgroundColor: '#702f8a',
  },
})(LinearProgress);
export default function CircularIndeterminate() {
  
  return (
    <div>
	 <ColorLinearProgress  />
    </div>
  );
}