import axiosInstance from "../services/API";
		
export function fileUpload(file,onUploadProgress) {
  return function (dispatch) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' },onUploadProgress };
   
    return axiosInstance
      .post("/api/upload",file,config)
      .then(response => {
        return response;
      })
      .catch(response => {
        console.log(response);
      });
  };
}

export function approveOrRejectCp(searchObj){
  return function(dispatch){
    return axiosInstance.post("/api/approveOrRejectCp",searchObj )
    .then(resp => {
      return resp.data;
    })
  };
} 

export function getAllCp(searchObj){
  return function(dispatch){
    return axiosInstance.post("/api/getAllCp",searchObj )
    .then(resp => {
      return resp.data;
    })
  };
} 
export function getProcessLog(param){
  return function(dispatch){
    return axiosInstance.post("/api/getProcessLog",param )
    .then(resp => {
      return resp.data;
    })
  };
} 
export function getCPData(param){
  return function(dispatch){
    return axiosInstance.post("/api/getCPData",param )
    .then(resp => {
      return resp.data;
    })
  };
}

export function getCPItems(param){
  return function(dispatch){
    return axiosInstance.post("/api/getCPItems",param )
    .then(resp => {
      return resp.data;
    })
  };
}

export function deleteCP(param){
  return function(dispatch){
    return axiosInstance.post("/api/deleteCP",param )
    .then(resp => {
      return resp.data;
    })
  };
}

export function exportCP(param){
  return function(dispatch){
    return axiosInstance.post("/api/exportCP",param )
    .then(resp => {
      return resp.data;
    })
  };
}