import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PAAStep4 = (props) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="flex-start">
      <div className="cb-type-article noTitleBar" key="preassessment">
        <div className="cb-item-text">
          <h2 className="cb-item-title">High School Complete Testers</h2>
          <p className="cb-item-des">
            If your scores don&rsquo;t meet the college ready benchmark, your
            institution is required to provide you with an appropriate action
            plan to help you become college ready in the respective subject
            area(s). Based on the strength of your scores, you may be placed in
            a development education course/intervention or a corequisite model
            course. A corequisite model course allows you to enroll in a
            college-level course while taking a developmental education
            course/intervention to help ensure your success in the college-level
            course. Contact the adviser at your college or university for more
            information.
          </p>
          <h2 className="cb-item-title">Planning to Retest</h2>
          <p className="cb-item-des">
            As a reminder, you may retest at any time. Instructional and
            practice materials are available at no cost in the TSIA2 Learning
            Resources library. It is highly recommended that you set aside time
            for additional study if you feel that you could do better by
            retesting. Taking the extra time to study prior to retesting can
            improve your scores. If you have done your absolute best, and you
            are unable to meet the college readiness benchmark(s), developmental
            education courses/interventions will better prepare you to succeed
            in future college classes.
          </p>
        </div>
      </div>
    </Grid>
  );
};
export default PAAStep4;
