import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import { Grid, CardContent, Card } from '@material-ui/core';
import { useDispatch } from 'react-redux'
import { getProcessLog } from '../../redux/importActions';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const headRows = [
    { id: 'fileName', numeric: false, disablePadding: true, label: 'File Name' },
    { id: 'errors', numeric: false, disablePadding: false, label: 'Errors' },
]

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={{textAlign:'center'}}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    titleBar: {
        borderBottom: '4px solid #0077c8',
        marginBottom: '15px'
    },
    print: {
        position: "absolute",
        bottom: "30%",
        right: "2%"
    }
}))

export default function ImportError(props) {
    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('Score')
    const [selected] = React.useState([])
    const [errorList, setErrorList] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const dispatch = useDispatch();

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }


    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    useEffect(() => {
        dispatch(getProcessLog({ cpUploadId: props.match.params.id })).then(res => {
            let errorLogs = []
            for (let key in res) {
                if (Array.isArray(res[key])) {
                    errorLogs.push({ fileName: key, errors: res[key] })
                }
            }
            setErrorList(errorLogs);
        });
    }, [])

    return (
        <React.Fragment>
            <Grid item xs={12} className='titleBar col-xs-12' align='center'>
                <Typography variant='h5' component='h3' color='primary'>
                    Import Errors
          </Typography>
            </Grid>
            <Grid item xs={12} >
                <div className="col-sm-12" >
                    <Card className={classes.card}>
                        <CardContent>
                        {errorList.length > 0 ? (
                            <React.Fragment>
                            <div className="table-wrapper">
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size='medium'
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={errorList.length}
                                    />
                                    <TableBody>
                                        {stableSort(errorList, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.id}
                                                    >

                                                        <TableCell component="th" scope="row">
                                                            <span>{row.fileName}</span>
                                                        </TableCell>
                                                        <TableCell style={{ whiteSpace: 'pre-line',textAlign:'center' }}>{row.errors.join("\n")}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250]}
                                component="div"
                                count={errorList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            /> </React.Fragment>) : <div className='table-noRecords'>No records to display</div>}
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        </React.Fragment>
    )
}