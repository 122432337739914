
import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux'
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { getTestVersion } from '../../redux/auth/action';
import { hideAlert } from '../../redux/alertActions';
import { showProgress, hideProgress } from "../../redux/progressActions";
import Box from '@material-ui/core/Box';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  ulStyle: {
    justifyContent: 'center'
  },
  avatar: {
    backgroundColor: '#0077c8',
    color: 'white'
  },
  cardButton: {
    display: 'block',
    textAlign: 'initial'
  },
  gridItem: {
    margin: '0 0 15px 0'
  },
  card: {
    minHeight: '100%'
  },
  cardContentPadding: {
    padding: '10px 16px'
  },
  cardTitle: {
    margin: '0',
    padding: '0',
    fontSize: '1.125rem',
    lineHeight: '1.33333333em',
    color: '#505050',
    fontFamily: 'Roboto Slab Bold,sans-serif'
  },
  cardHeader: {
    // minHeight: "100px"
  },
  cardAction: {
    // position: "absolute",
    // bottom: 0,
    // right: "25px"
    flexDirection: 'row-reverse'
  },
  noPadding: {
    padding: 0
  }
}));

const Tests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [testVersions, setTestVersion] = useState([]);

  const testVersionElements = (testVersions && testVersions.length) ? (testVersions.map((test,i) => (
    <li className='cb-type-article noTitleBar' key={i}>
      <Link to={"/home/test/"+test.categoryId}>
        <div className='cb-item-icon'>
          <span className='cb-glyph cb-glyph-circular cb-glyph-higher-education cb-compose' aria-hidden='true'></span>
        </div>
        <div className='cb-item-text'>
        <p className='cb-item-title'>{test.categoryName}</p> 
          {test.sequence == 3 ?<span className="item-title-info">Texas Student? Start Here--You must take the PAA prior to the TSIA2.</span>: ''}
     </div>
        <Box component="div" display="block" className='pull-right' m={0}>
          <ArrowForwardIcon/>
        
        </Box>
      </Link>
    </li>
  ))) : (<li className='cb-type-article noTitleBar'>
          <p className='text-center'>No Tests to Display</p>       
  </li>);
  
  useEffect(() => {
    dispatch(hideAlert());
    dispatch(showProgress());
    dispatch(getTestVersion()).then((res) => {
      if(res && res.testVersion) {
        setTestVersion(res.testVersion)
      } else {
        setTestVersion([]);
      }
      
      dispatch(hideProgress());
    })
  }, []);

  return (
    <Grid container direction='row' justifyContent='center' alignItems='stretch'>
        <Grid item xs={12} className='titleBar' align='center'>
          <Typography variant='h5' component='h1' color='primary'>
            Choose a Practice Test
        </Typography>
        </Grid>
        <Grid item className='col-xs-12 col-sm-7 cb-item-list cb-item-list-rich'>
          <ul className={classes.ulStyle}>{testVersionElements}</ul>
        </Grid>
      </Grid>
      );
    };
    export default Tests;
