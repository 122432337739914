import axiosInstance from "../services/API";
import ActionTypes from "../constants/ActionTypes";

import { showModal, hideModal } from "./modalAction";

import { showProgress, hideProgress } from "./progressActions";

import { getTests } from "./userActions";

export function getCurrentPAASession() {
  return function (dispatch) {
    return axiosInstance
      .get("/api/getCurrentPAASession")
      .then((resp) => {
        let currentPAAData = resp.data;
        if (currentPAAData) {
          if(currentPAAData.lastCompletedStep >= 1){
           currentPAAData.videosWatched = true;
          }
          currentPAAData.lastCompletedStep++;
          dispatch({
            type: ActionTypes.UPDATE_PAA,
            data: currentPAAData,
          });
        } else {
          dispatch(resetCurrentPAASession());
        }
        return currentPAAData;
      })
      .catch((response) => {
        console.log(response);
      });
  };
}

function updateCurrentPAASession(paaObj) {
  let url =
    paaObj.lastCompletedStep === 0 && !paaObj.paaId
      ? "/api/createPAASession"
      : "/api/updateCurrentPAASession";
  return function (dispatch) {
    return axiosInstance
      .post(url, paaObj)
      .then((resp) => {
        const updatedPAAObj = resp.data;
        if (updatedPAAObj) {
          // go to next step once the data is stored.
          if(updatedPAAObj.lastCompletedStep >= 1){
            updatedPAAObj.videosWatched = true;
          }
          updatedPAAObj.lastCompletedStep++;
          dispatch({
            type: ActionTypes.UPDATE_PAA,
            data: updatedPAAObj,
          });
        }

        return resp.data;
      })
      .catch((response) => {
        console.log(response);
      });
  };
}

export function resetCurrentPAASession() {
  return function (dispatch) {
    const initialPAAData = {
      lastCompletedStep: 0,
    };
    dispatch({
      type: ActionTypes.UPDATE_PAA,
      data: initialPAAData,
    });
  };
}

export function validateStep(paaObj) {
  return async function (dispatch) {
    dispatch(showProgress());
    let isStepValid = true;
    switch (paaObj.lastCompletedStep + 1) {
      case 1:
        if (paaObj) {
          if (!paaObj.institutionId) {
            paaObj.institutionId = null;
            isStepValid = false;
          }
          if (!paaObj.agreementChecked) {
            paaObj.agreementChecked = false;
            isStepValid = false;
          }
          if (!paaObj.studentDOB || paaObj.studentDOB === "") {
            paaObj.studentDOB = null;
            isStepValid = false;
          }
        } else {
          isStepValid = false;
        }
        break;
      case 2:
        if(paaObj){
          if(!paaObj.videosWatched){
            isStepValid = false;
          }
        }
        if (!isStepValid) {
          dispatch(openStep2VideoAlertModal());
        }
      break;
      case 3:
        const testCategoryData = {
          categoryId: 5,
          paaId: paaObj.paaId,
        };
        paaObj.step3Data = undefined;
        await dispatch(getTests(testCategoryData)).then((res) => {
          paaObj.step3Data = res.testData;
        });
        if (paaObj.step3Data) {
          paaObj.step3Data.forEach((element) => {
            if (
              !element.completedLAYGSessionId ||
              element.completedLAYGSessionId == 0
            ) {
              isStepValid = false;
            }
          });
          if (!isStepValid) {
            dispatch(openStep3TestIncompleteAlertModal());
          }
        }
        break;

      default:
        break;
    }
    return isStepValid;
  };
}

export function goToNextStep(paaObj) {
  return function (dispatch) {
    const paaObj1 = { ...paaObj };
    dispatch(validateStep(paaObj1)).then((isStepValid) => {
      dispatch(hideProgress());
      if (!isStepValid) {
        dispatch({
          type: ActionTypes.UPDATE_PAA,
          data: paaObj1,
        });
      } else {
        return dispatch(updateCurrentPAASession(paaObj));
      }
    });
  };
}

export function finishPAASession(paaObj) {
  return function (dispatch) {
    const paaObj1 = { ...paaObj };
    dispatch(validateStep(paaObj1)).then((isStepValid) => {
      dispatch(hideProgress());
      if (!isStepValid) {
        dispatch({
          type: ActionTypes.UPDATE_PAA,
          data: paaObj1,
        });
      } else {
        paaObj.status = 1;
        return dispatch(updateCurrentPAASession(paaObj));
      }
    });
  };
}

export function goToPreviousStep(paaObj) {
  return function (dispatch) {
    paaObj.lastCompletedStep--;
    dispatch({
      type: ActionTypes.UPDATE_PAA,
      data: paaObj,
    });
  };
}

export function getTexasInstitutions() {
  return function (dispatch) {
    return axiosInstance
      .get("/api/getTexasInstitutions")
      .then((resp) => {
        return resp.data;
      })
      .catch((response) => {
        console.log(response);
      });
  };
}

export function getPAACertificateLink(paaId) {
  return function (dispatch) {
    return axiosInstance
      .get("/api/getPAACertificate/" + paaId)
      .then((resp) => {
        return resp.data;
      })
      .catch((response) => {
        console.log(response);
      });
  };
}

export function printResources(paaObj) {
    //add logic if multiple steps have print option
    return function (dispatch) {
        return dispatch(getPAAResourceLink(paaObj.paaId)); 
    }
}
export function getPAAResourceLink(paaId) {
  return function (dispatch) {
    return axiosInstance
      .get("/api/getPAAResource/" + paaId)
      .then((resp) => {
        return resp.data;
      })
      .catch((response) => {
        console.log(response);
      });
  };
}

const openStep3TestIncompleteAlertModal = () => {
  return function (dispatch) {
    let alertTitle = "Tests Incomplete!";
    let alertMessage = "Please complete both sample tests to proceed.";
    dispatch(
      showModal(
        {
          title: alertTitle,
          message: alertMessage,
        },
        "alert"
      )
    );
  };
};
const openStep2VideoAlertModal = () => {
  return function (dispatch) {
    let alertTitle = "Videos Unwatched!";
    let alertMessage = "Please watch all videos to proceed.";
    dispatch(
      showModal(
        {
          title: alertTitle,
          message: alertMessage,
        },
        "alert"
      )
    );
  };
};

export function getStep2VideoUrlList() {
  return function (dispatch) {
    return axiosInstance
      .get("/api/getStep2VideoUrlList")
      .then((resp) => {
        return resp.data;
      })
      .catch((response) => {
        console.log(response);
      });
  };
}
