import C from "./constants";
export default (state = { showProgress: false }, action) => {
  switch (action.type) {
    case C.SHOW_PROGRESS:
      return {
        showProgress: true
      };

    case C.REMOVE_PROGRESS:
      return {
        showProgress: false
      };
    default:
      return {
        ...state
      };
  }
};
