

export function PasswordValidationRegex(value) {
  // custom rule for password validation

  if (value !== "") {
    if (value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)
      || value.match(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])/)
      || value.match(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])/)
      || value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&.])/)) {
      if (value.match(/^.{9,}$/)) {
        return true;
      }
      else {
        return false;
      }
    }
  } else {
    return true
  }

}

